import { Col, Form, FormInstance, InputNumber, Row, TimePicker } from 'antd';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

interface Props {
  form: FormInstance<any>;
}

const PresenceForm = ({ form }: Props) => {
  const { t } = useTranslation('work-logs');

  const calculateDailyHours = (
    startTime: dayjs.Dayjs,
    endTime: dayjs.Dayjs
  ) => {
    if (startTime && endTime && endTime.isAfter(startTime)) {
      const diffInHours = endTime.diff(startTime, 'hour', true);
      form.setFieldsValue({
        dailyWorkHours: parseFloat(diffInHours.toFixed(1)),
      });
    } else {
      form.setFieldsValue({ dailyWorkHours: null });
    }
  };

  return (
    <Row gutter={16}>
      <Col xs={24} sm={24} md={24} lg={12}>
        <Form.Item
          label={t('view.columns.startTime')}
          name='startTime'
          rules={[
            {
              validator: (_, value) => {
                const startTime = value;
                const endTime = form.getFieldValue('endTime');
                if (startTime && endTime && startTime.isAfter(endTime)) {
                  return Promise.reject(
                    new Error(t('edit.validate.end_after_start'))
                  );
                }
                return Promise.resolve();
              },
            },
          ]}
        >
          <TimePicker
            format='HH:mm'
            className='w-100'
            onChange={(value) => {
              if (value) {
                const startTime = value;
                const endTime = form.getFieldValue('endTime');
                calculateDailyHours(startTime, endTime);
              }
            }}
          />
        </Form.Item>
      </Col>
      <Col xs={24} sm={24} md={24} lg={12}>
        <Form.Item
          label={t('view.columns.endTime')}
          name='endTime'
          rules={[
            {
              validator: (_, value) => {
                const startTime = form.getFieldValue('startTime');
                const endTime = value;

                if (endTime && startTime && endTime.isBefore(startTime)) {
                  return Promise.reject(
                    new Error(t('edit.validate.end_after_start'))
                  );
                }
                return Promise.resolve();
              },
            },
          ]}
        >
          <TimePicker
            format='HH:mm'
            className='w-100'
            onChange={(value) => {
              if (value) {
                const startTime = form.getFieldValue('startTime');
                const endTime = value;
                calculateDailyHours(startTime, endTime);
              }
            }}
          />
        </Form.Item>
      </Col>
      <Col xs={24} sm={24} md={24} lg={12}>
        <Form.Item
          label={t('view.columns.dailyWorkHours')}
          name='dailyWorkHours'
        >
          <InputNumber
            min={0}
            max={24}
            step={0.5}
            className='w-100'
            addonAfter={'h'}
          />
        </Form.Item>
      </Col>
      <Col xs={24} sm={24} md={24} lg={12}>
        <Form.Item
          label={t('view.columns.dailyBreakMinutes')}
          name='dailyBreakMinutes'
        >
          <InputNumber
            min={0}
            max={480}
            step={5}
            className='w-100'
            addonAfter={'min'}
          />
        </Form.Item>
      </Col>
      <Col xs={24} sm={24} md={24} lg={12}>
        <Form.Item
          label={t('view.columns.nightWorkHours')}
          name='nightWorkHours'
        >
          <InputNumber
            min={0}
            max={24}
            step={0.5}
            className='w-100'
            addonAfter={'h'}
          />
        </Form.Item>
      </Col>
      <Col xs={24} sm={24} md={24} lg={12}>
        <Form.Item label={t('view.columns.overtimeHours')} name='overtimeHours'>
          <InputNumber
            min={0}
            max={24}
            step={0.5}
            className='w-100'
            addonAfter={'h'}
          />
        </Form.Item>
      </Col>
      <Col xs={24} sm={24} md={24} lg={12}>
        <Form.Item
          label={t('view.columns.holidayWorkHours')}
          name='holidayWorkHours'
        >
          <InputNumber
            min={0}
            max={24}
            step={0.5}
            className='w-100'
            addonAfter={'h'}
          />
        </Form.Item>
      </Col>
      <Col xs={24} sm={24} md={24} lg={12}>
        <Form.Item
          label={t('view.columns.businessTripHours')}
          name='businessTripHours'
        >
          <InputNumber
            min={0}
            max={24}
            step={0.5}
            className='w-100'
            addonAfter={'h'}
          />
        </Form.Item>
      </Col>
      <Col xs={24} sm={24} md={24} lg={12}>
        <Form.Item label={t('view.columns.standbyHours')} name='standbyHours'>
          <InputNumber
            min={0}
            max={24}
            step={0.5}
            className='w-100'
            addonAfter={'h'}
          />
        </Form.Item>
      </Col>
    </Row>
  );
};

export default PresenceForm;
