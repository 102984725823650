import { Navigate, Route, Routes } from 'react-router-dom';

import Activate from '../../components/layout/pages/activate.component';
import { Login } from '../../components/login';

interface Props {
  intendedDestination?: string;
}

export function AuthRouter(props: Props) {
  const { intendedDestination } = props;

  return (
    <Routes>
      <Route path='/login' element={<Login />} />
      <Route path='/activate' element={<Activate />} />
      <Route path='/change-password' element={<Activate />} />
      <Route
        path='*'
        element={
          <Navigate
            to='/login'
            replace
            state={{ 'intended-destination': intendedDestination }}
          />
        }
      />
    </Routes>
  );
}
