import i18n from 'i18next';
import httpBackend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';

declare module 'i18next' {
  interface CustomTypeOptions {
    returnNull: false;
  }
}

i18n
  .use(httpBackend)
  .use(initReactI18next)
  .init({
    backend: {
      loadPath: '/locales/{{lng}}/{{ns}}.json',
    },

    detection: {
      order: ['navigator'],
    },

    load: 'languageOnly',
    supportedLngs: ['hr', 'en'],
    fallbackLng: 'hr',
    lng: 'hr',

    interpolation: {
      escapeValue: false,
    },
    ns: ['common', 'error'],

    react: { useSuspense: false },
    returnNull: false,
  });

export default i18n;
