import axios from 'axios';

import { API_URL } from '../../constants/constants';
import { PaginatedData } from '../../types/dto/common.dto';
import {
  BusinessTripRequestDto,
  CreateBusinessTripRequestDto,
  GetBusinessTripRequestsDto,
} from '../../types/dto/work-logs.dto';

export class BusinessTripRequests {
  async getBusinessTripRequests(
    getBusinessTripRequests: GetBusinessTripRequestsDto
  ): Promise<PaginatedData<BusinessTripRequestDto[]>> {
    const { page, pageSize, status } = getBusinessTripRequests;
    const params = new URLSearchParams();

    params.append('page', page.toString());
    params.append('pageSize', pageSize.toString());

    if (status) {
      status.forEach((s) => {
        params.append('status[]', s);
      });
    }

    const response = await axios({
      method: 'GET',
      url: `${API_URL}/api/business-trip-request`,
      responseType: 'json',
      params,
    });

    return response.data;
  }

  async createBusinessTripRequest(createRequest: CreateBusinessTripRequestDto) {
    return await axios({
      method: 'POST',
      url: `${API_URL}/api/business-trip-request`,
      responseType: 'json',
      data: createRequest,
    });
  }

  async deleteBusinessTripRequest(id: string) {
    const params = new URLSearchParams();
    params.append('requestId', id);

    return await axios({
      method: 'DELETE',
      url: `${API_URL}/api/business-trip-request`,
      responseType: 'json',
      params,
    });
  }
}
