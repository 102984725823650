import axios from 'axios';

import { API_URL } from '../../constants/constants';
import { PaginatedData } from '../../types/dto/common.dto';
import { CreateUpdateEventDto, EventDto } from '../../types/dto/event.dto';

export class Events {
  async getEvents(
    startDate: string,
    endDate: string,
    page: number,
    pageSize: number
  ): Promise<PaginatedData<EventDto[]>> {
    const params = new URLSearchParams();
    params.append('startDate', startDate);
    params.append('endDate', endDate);
    params.append('page', page.toString());
    params.append('pageSize', pageSize.toString());

    const response = await axios({
      method: 'GET',
      url: `${API_URL}/api/events`,
      responseType: 'json',
      params,
    });

    return response.data;
  }

  async getEventById(eventId: string): Promise<EventDto> {
    const params = new URLSearchParams();
    params.append('eventId', eventId);

    const response = await axios({
      method: 'GET',
      url: `${API_URL}/api/events/one`,
      responseType: 'json',
      params,
    });

    return response.data;
  }

  async createEvent(createEventDto: CreateUpdateEventDto) {
    return await axios({
      method: 'POST',
      url: `${API_URL}/api/events`,
      responseType: 'json',
      data: createEventDto,
    });
  }

  async updateEvent(evendId: string, updateEventDto: CreateUpdateEventDto) {
    const params = new URLSearchParams();
    params.append('eventId', evendId);

    return await axios({
      method: 'PATCH',
      url: `${API_URL}/api/events`,
      responseType: 'json',
      data: updateEventDto,
      params,
    });
  }

  async deleteEvent(evendId: string) {
    const params = new URLSearchParams();
    params.append('eventId', evendId);

    return await axios({
      method: 'DELETE',
      url: `${API_URL}/api/events`,
      responseType: 'json',
      params,
    });
  }
}
