import { Button, Flex, Form, Grid, Input, notification } from 'antd';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { Server } from '../../../api/server-index';

const Activate = () => {
  const [params] = useSearchParams();
  const navigate = useNavigate();
  const token = params.get('token');
  const { t } = useTranslation('settings');
  const sizing = Grid.useBreakpoint();

  const activateAccount = async (values: any) => {
    if (token) {
      const response = await Server.User.setPassword({
        password: values.password,
        activationToken: token,
      });

      if (response.status === 200) {
        notification.success({
          message: t('password.activation.set_success'),
        });
        navigate('/login');
      }
    } else {
      notification.error({ message: t('password.activation.no_token') });
    }
  };

  return (
    <div className='min-vh-70 d-flex justify-content-center align-items-center'>
      <div className='login' style={{ width: '300px' }}>
        <div className='text-center mb-1'>
          <img
            src={'/assets/aspira-uciliste-w.png'}
            alt='Logo'
            className='img-fluid mw-100'
          />
        </div>
        <Flex vertical align='center'>
          <Form
            name='activate-account'
            onFinish={activateAccount}
            className='w-100'
          >
            <h2 className='text-white text-center'>
              {t('password.activation.title')}
            </h2>
            <Form.Item
              name='password'
              rules={[
                { required: true, message: t('password.activation.required') },
              ]}
              validateTrigger='onBlur'
              wrapperCol={sizing.xs === true ? undefined : { offset: 0 }}
            >
              <Input.Password placeholder={t('password.activation.password')} />
            </Form.Item>
            <Form.Item
              name='confirm_password'
              wrapperCol={sizing.xs === true ? undefined : { offset: 0 }}
              dependencies={['password']}
              rules={[
                {
                  required: true,
                  message: t('password.activation.required'),
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue('password') === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error(t('password.activation.no_match'))
                    );
                  },
                }),
              ]}
              validateTrigger='onBlur'
            >
              <Input.Password
                placeholder={t('password.activation.confirm_password')}
              />
            </Form.Item>
            <Form.Item
              wrapperCol={sizing.xs === true ? undefined : { offset: 0 }}
            >
              <Button htmlType='submit' className='logout-button w-100'>
                {t('password.activation.submit')}
              </Button>
            </Form.Item>
          </Form>
        </Flex>
      </div>
    </div>
  );
};

export default Activate;
