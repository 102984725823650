import { ArrowLeftOutlined } from '@ant-design/icons';
import { Button, Tooltip } from 'antd';
import { MouseEventHandler } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

interface BackButtonProps {
  href?: string;
  onClick?: MouseEventHandler<HTMLElement>;
}

const BackButton = (props: BackButtonProps) => {
  const { t } = useTranslation();

  return props.href ? (
    <Tooltip title={t('common:back')}>
      <Link to={props.href}>
        <Button onClick={props.onClick}>
          <ArrowLeftOutlined />
        </Button>
      </Link>
    </Tooltip>
  ) : (
    <Tooltip title={t('common:back')}>
      <Button onClick={props.onClick}>
        <ArrowLeftOutlined />
      </Button>
    </Tooltip>
  );
};

export default BackButton;
