import React from 'react';
import ReactDOM from 'react-dom/client';

import App from './app';
import './i18n';
import { AuthProvider } from './context/auth-context';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <AuthProvider>
    <App />
  </AuthProvider>
);
