import { PrinterOutlined } from '@ant-design/icons';
import { Button, Flex, QRCode } from 'antd';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

import { EventCheckInCodeDto } from '../../../../../../../types/dto/event-check-in.dto';
import { EventDto } from '../../../../../../../types/dto/event.dto';

interface Props {
  checkInData: {
    event: EventDto;
    qrData: EventCheckInCodeDto;
  };
}

const EventQRCode = ({ checkInData }: Props) => {
  const { t } = useTranslation('common');

  const handlePrint = () => {
    const printableContent = document.getElementById('qr-print');
    if (printableContent) {
      const printWindow = window.open('', '_blank');
      if (printWindow) {
        const printDocument = printWindow.document;

        const htmlContent = printableContent.outerHTML;
        const styles = Array.from(document.styleSheets)
          .map((styleSheet) => {
            try {
              return Array.from(styleSheet.cssRules)
                .map((rule) => rule.cssText)
                .join('\n');
            } catch (e) {
              console.log('Error while reading CSS rules from stylesheet:', e);
              return '';
            }
          })
          .join('\n');

        printDocument.write(`
          <html>
            <head>
              <title>QR Print</title>
              <style>
                ${styles}
                body {
                  font-family: 'Arial', sans-serif; 
                }
              </style>
            </head>
            <body>
              ${htmlContent}
            </body>
          </html>
        `);
        printDocument.close();

        printWindow.focus();
        printWindow.print();
      }
    }
  };

  return (
    <Flex vertical>
      <div className='desktop-qr'>
        <div id='qr-print'>
          <Flex vertical className='mb-1'>
            <h2 className='m-0'>{checkInData.event.subjectName}</h2>
            <span className='text-gray'>{`${dayjs(
              checkInData.event.dateStart
            ).format('DD.MM.YYYY.')} ${dayjs(
              checkInData.event.dateStart
            ).format('HH:mm')} - ${dayjs(checkInData.event.dateEnd).format(
              'HH:mm'
            )}`}</span>
            <span className='text-gray'>{checkInData.event.classroom}</span>
          </Flex>
          <QRCode
            value={checkInData?.qrData.checkInUrl}
            type='svg'
            size={520}
          />
        </div>
        <h2 className='text-center'>{checkInData.qrData.checkInUrl}</h2>
      </div>

      <div className='mobile-qr'>
        <Flex vertical className='mb-1'>
          <h2 className='m-0'>{checkInData.event.subjectName}</h2>
          <span className='text-gray'>{`${dayjs(
            checkInData.event.dateStart
          ).format('DD.MM.YYYY.')} ${dayjs(checkInData.event.dateStart).format(
            'HH:mm'
          )} - ${dayjs(checkInData.event.dateEnd).format('HH:mm')}`}</span>
          <span className='text-gray'>{checkInData.event.classroom}</span>
        </Flex>
        <QRCode value={checkInData?.qrData.checkInUrl} type='svg' size={320} />
        <h3 className='text-center'>{checkInData.qrData.checkInUrl}</h3>
      </div>

      <Flex className='print-qr-button' justify='flex-end'>
        <Button
          type='primary'
          ghost
          icon={<PrinterOutlined />}
          onClick={handlePrint}
        >
          {t('common:print')}
        </Button>
      </Flex>
    </Flex>
  );
};

export default EventQRCode;
