import { TeamOutlined } from '@ant-design/icons';
import {
  Alert,
  Button,
  Card,
  Col,
  Collapse,
  Divider,
  Flex,
  List,
  Row,
  Spin,
  Tag,
} from 'antd';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { Server } from '../../../../../../api/server-index';
import { AuthContext } from '../../../../../../context/auth-context';
import { SubjectDto } from '../../../../../../types/dto/subject.dto';
import { getTagClassName } from '../../../../../../utils/get-tag-class-name';
import MainTitle from '../../../../../common/titles/main-title.component';

const SubjectList = () => {
  const [loading, setLoading] = useState<boolean>(true);
  const [subjects, setSubjects] = useState<SubjectDto[]>([]);
  const { t } = useTranslation('subjects');
  const { language, currentAcademicYear } = useContext(AuthContext);

  const fetchSubjects = useCallback(async () => {
    setLoading(true);
    const response = await Server.Subjects.getSubjectsForLecturer(
      currentAcademicYear
    );

    setSubjects(response);
    setLoading(false);
  }, [currentAcademicYear]);

  useEffect(() => {
    fetchSubjects();
  }, [fetchSubjects]);

  const renderMoodleAlert = (numberOfMoodleCourses: number) => {
    switch (numberOfMoodleCourses) {
      case 0:
        return <Alert showIcon type='error' description={t('moodleAlert0')} />;
      case 1:
        return (
          <Alert showIcon type='warning' description={t('moodleAlert1')} />
        );
      default:
        return (
          <Alert
            showIcon
            type='info'
            description={t('moodleAlertMultiple', {
              number: numberOfMoodleCourses,
            })}
          />
        );
    }
  };

  return (
    <Spin spinning={loading}>
      <MainTitle
        text={`${t('title')} (${currentAcademicYear}/${
          currentAcademicYear + 1
        })`}
      />
      {subjects.length !== 0 ? (
        <Collapse
          size='large'
          className='mt-05'
          accordion
          destroyInactivePanel
          items={subjects.map((subject) => ({
            key: subject.id,
            label: (
              <span>
                {language === 'en'
                  ? subject.subjectNameEn ?? subject.subjectName
                  : subject.subjectName}
              </span>
            ),
            children: (
              <Flex vertical>
                <Flex>
                  <Link to={`/subjects/students/${subject.id}`}>
                    <Button icon={<TeamOutlined />}>
                      {t('students.short_title')}
                    </Button>
                  </Link>
                </Flex>
                <Divider />
                <h4 className='m-0'>{t('programs.subject_programs')}</h4>
                <List>
                  {subject.programs?.map((program) => (
                    <List.Item key={program.id}>
                      <Row className='w-100' justify={'space-between'}>
                        <Col xs={24} sm={24} md={18} lg={18} xl={18}>
                          {program.programName}
                        </Col>
                        <Col xs={24} sm={24} md={6} lg={6} xl={6}>
                          <Flex className='mobile-between'>
                            <Tag className={getTagClassName(program.level)}>
                              {t(`programs.levels.${program.level}`)}
                            </Tag>

                            <Tag className={getTagClassName(program.mode)}>
                              {t(`programs.modes.${program.mode}`)}
                            </Tag>

                            <Tag>
                              {t(`programs.locations.${program.location}`)}
                            </Tag>
                          </Flex>
                        </Col>
                      </Row>
                    </List.Item>
                  ))}
                </List>
                <Divider />
                <h4 className='mt-05 mb-05'>{t('Moodle')}</h4>
                {renderMoodleAlert(subject.numberOfMoodleCourses!)}
              </Flex>
            ),
          }))}
        />
      ) : (
        <Card className='shadow-sm'>{t('no_subjects')}</Card>
      )}
    </Spin>
  );
};

export default SubjectList;
