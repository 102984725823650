import dayjs from 'dayjs';

export class DateUtils {
  public static isWeekendOrHoliday = (
    day: number,
    month: number,
    year: number
  ) => {
    const date = dayjs(`${year}-${month}-${day}`, 'YYYY-M-D');
    const dayOfWeek = date.day();

    const croatianHolidays = [
      '01-01', // New Year's Day
      '01-06', // Epiphany
      '05-01', // Labor Day
      '05-30', // State Day
      '06-22', // Anti-Fascist Day
      '08-05', // Victory Day
      '08-15', // Assumption of Mary
      '11-01', // All Saints' Day
      '11-18', // Rememberance Day
      '12-25', // Christmas
      '12-26', // St. Stephen's Day
      // Easter Monday and Corpus Christi?
    ];

    return (
      dayOfWeek === 0 ||
      dayOfWeek === 6 ||
      croatianHolidays.includes(date.format('MM-DD'))
    );
  };

  public static dayOfWeekShortname = (
    day: number,
    month: number,
    year: number
  ) => {
    const date = dayjs(`${year}-${month}-${day}`, 'YYYY-M-D');
    const dayOfWeek = date.day();

    switch (dayOfWeek) {
      case 0:
        return 'sun';
      case 1:
        return 'mon';
      case 2:
        return 'tue';
      case 3:
        return 'wed';
      case 4:
        return 'thu';
      case 5:
        return 'fri';
      case 6:
        return 'sat';
      default:
        break;
    }
  };

  public static getMonthName(monthNumber: number): string {
    const monthNames = [
      'jan',
      'feb',
      'mar',
      'apr',
      'may',
      'jun',
      'jul',
      'aug',
      'sep',
      'oct',
      'nov',
      'dec',
    ];

    return monthNames[monthNumber - 1];
  }
}
