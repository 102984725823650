import axios from 'axios';

import { API_URL } from '../../constants/constants';
import { PaginatedData } from '../../types/dto/common.dto';
import {
  CreateUpdateOtherExpenseDto,
  CreateWorksheetDto,
  GetOtherExpensesDto,
  GetWorksheetMetaDto,
  GetWorksheetNotesEventsDto,
  GetWorksheetsDto,
  OtherExpenseAttachmentDto,
  OtherExpensesDto,
  WorksheetDto,
  WorksheetEventDto,
  WorksheetEventUpdateDto,
  WorksheetMetaDto,
  WorksheetNoteCreateUpdateDto,
  WorksheetNoteDto,
} from '../../types/dto/worksheet.dto';

export class Worksheets {
  async getWorksheets(
    getWorksheetsDto: GetWorksheetsDto
  ): Promise<PaginatedData<WorksheetDto[]>> {
    const { status, page, pageSize, worksheetNr } = getWorksheetsDto;
    const params = new URLSearchParams();

    params.append('page', page.toString());
    params.append('pageSize', pageSize.toString());

    if (status) {
      status.forEach((s) => {
        params.append('status[]', s);
      });
    }

    if (worksheetNr) {
      params.append('worksheetNr', worksheetNr.toString());
    }

    const response = await axios({
      method: 'GET',
      url: `${API_URL}/api/worksheets`,
      responseType: 'json',
      params,
    });

    return response.data as PaginatedData<WorksheetDto[]>;
  }

  async getWorksheetMeta(
    getWorksheetMeta: GetWorksheetMetaDto
  ): Promise<PaginatedData<WorksheetMetaDto[]>> {
    const { page, pageSize, worksheetId } = getWorksheetMeta;
    const params = new URLSearchParams();

    params.append('worksheetId', worksheetId);
    params.append('page', page.toString());
    params.append('pageSize', pageSize.toString());

    const response = await axios({
      method: 'GET',
      url: `${API_URL}/api/worksheets/meta`,
      responseType: 'json',
      params,
    });

    return response.data as PaginatedData<WorksheetMetaDto[]>;
  }

  async getWorksheet(worksheetId: string): Promise<WorksheetDto> {
    const params = new URLSearchParams();
    params.append('worksheetId', worksheetId);

    const response = await axios({
      method: 'GET',
      url: `${API_URL}/api/worksheets/one`,
      responseType: 'json',
      params,
    });

    return response.data as WorksheetDto;
  }

  async createWorksheet(createWorksheetDto: CreateWorksheetDto) {
    return await axios({
      method: 'POST',
      url: `${API_URL}/api/worksheets`,
      responseType: 'json',
      data: createWorksheetDto,
    });
  }

  async deleteWorksheet(worksheetId: string) {
    const params = new URLSearchParams();
    params.append('worksheetId', worksheetId);

    return await axios({
      method: 'DELETE',
      url: `${API_URL}/api/worksheets`,
      responseType: 'json',
      params,
    });
  }

  async rescanWorksheet(worksheetId: string) {
    const params = new URLSearchParams();
    params.append('worksheetId', worksheetId);

    return await axios({
      method: 'PATCH',
      url: `${API_URL}/api/worksheets/rescan`,
      responseType: 'json',
      params,
    });
  }

  async submitWorksheet(worksheetId: string) {
    const params = new URLSearchParams();
    params.append('worksheetId', worksheetId);

    return await axios({
      method: 'PATCH',
      url: `${API_URL}/api/worksheets/submit`,
      responseType: 'json',
      params,
    });
  }

  async withdrawWorksheet(worksheetId: string) {
    const params = new URLSearchParams();
    params.append('worksheetId', worksheetId);

    return await axios({
      method: 'PATCH',
      url: `${API_URL}/api/worksheets/withdraw`,
      responseType: 'json',
      params,
    });
  }

  async getWorksheetEvents(
    getWorksheetEventsDto: GetWorksheetNotesEventsDto
  ): Promise<PaginatedData<WorksheetEventDto[]>> {
    const { page, pageSize, worksheetId } = getWorksheetEventsDto;
    const params = new URLSearchParams();
    params.append('worksheetId', worksheetId);
    params.append('page', page.toString());
    params.append('pageSize', pageSize.toString());

    const response = await axios({
      method: 'GET',
      url: `${API_URL}/api/worksheets/events`,
      responseType: 'json',
      params,
    });

    return response.data as PaginatedData<WorksheetEventDto[]>;
  }

  async getWorksheetEvent(wsEventId: string): Promise<WorksheetEventDto> {
    const params = new URLSearchParams();
    params.append('wsEventId', wsEventId);

    const response = await axios({
      method: 'GET',
      url: `${API_URL}/api/worksheets/events/one`,
      responseType: 'json',
      params,
    });

    return response.data as WorksheetEventDto;
  }

  async updateWorksheetEvent(
    wsEventId: string,
    worksheetEventUpdate: WorksheetEventUpdateDto
  ) {
    const params = new URLSearchParams();
    params.append('wsEventId', wsEventId);

    return await axios({
      method: 'PATCH',
      url: `${API_URL}/api/worksheets/events`,
      responseType: 'json',
      params,
      data: worksheetEventUpdate,
    });
  }

  async deleteWorksheetEvent(wsEventId: string) {
    const params = new URLSearchParams();
    params.append('wsEventId', wsEventId);

    return await axios({
      method: 'DELETE',
      url: `${API_URL}/api/worksheets/events`,
      responseType: 'json',
      params,
    });
  }

  async getNotes(
    getWorksheetNotesDto: GetWorksheetNotesEventsDto
  ): Promise<PaginatedData<WorksheetNoteDto[]>> {
    const { page, pageSize, worksheetId } = getWorksheetNotesDto;
    const params = new URLSearchParams();
    params.append('page', page.toString());
    params.append('pageSize', pageSize.toString());
    params.append('worksheetId', worksheetId);

    const response = await axios({
      method: 'GET',
      url: `${API_URL}/api/worksheets/notes`,
      responseType: 'json',
      params,
    });

    return response.data as PaginatedData<WorksheetNoteDto[]>;
  }

  async createNote(
    worksheetId: string,
    createNoteDto: WorksheetNoteCreateUpdateDto
  ) {
    const params = new URLSearchParams();
    params.append('worksheetId', worksheetId);

    return await axios({
      method: 'POST',
      url: `${API_URL}/api/worksheets/notes`,
      responseType: 'json',
      params,
      data: createNoteDto,
    });
  }

  async updateNote(noteId: string, noteContent: WorksheetNoteCreateUpdateDto) {
    const params = new URLSearchParams();
    params.append('noteId', noteId);

    return await axios({
      method: 'PATCH',
      url: `${API_URL}/api/worksheets/notes`,
      responseType: 'json',
      data: noteContent,
      params,
    });
  }

  async deleteNote(noteId: string) {
    const params = new URLSearchParams();
    params.append('noteId', noteId);

    return await axios({
      method: 'DELETE',
      url: `${API_URL}/api/worksheets/notes`,
      responseType: 'json',
      params,
    });
  }

  async getOtherExpenses(
    getOtherExpensesDto: GetOtherExpensesDto
  ): Promise<PaginatedData<OtherExpensesDto[]>> {
    const params = new URLSearchParams();
    params.append('page', getOtherExpensesDto.page.toString());
    params.append('pageSize', getOtherExpensesDto.pageSize.toString());
    params.append('worksheetId', getOtherExpensesDto.worksheetId);

    const response = await axios({
      method: 'GET',
      url: `${API_URL}/api/worksheets/other-expense`,
      responseType: 'json',
      params,
    });

    return response.data;
  }

  async createOtherExpense(
    worksheetId: string,
    createOtherExpenseDto: CreateUpdateOtherExpenseDto
  ) {
    const params = new URLSearchParams();
    params.append('worksheetId', worksheetId);

    return await axios({
      method: 'POST',
      url: `${API_URL}/api/worksheets/other-expense`,
      responseType: 'json',
      params,
      data: createOtherExpenseDto,
    });
  }

  async updateOtherExpense(
    otherId: string,
    updateOtherExpenseDto: CreateUpdateOtherExpenseDto
  ) {
    const params = new URLSearchParams();
    params.append('otherId', otherId);

    return await axios({
      method: 'PATCH',
      url: `${API_URL}/api/worksheets/other-expense`,
      responseType: 'json',
      params,
      data: updateOtherExpenseDto,
    });
  }

  async deleteOtherExpense(otherId: string) {
    const params = new URLSearchParams();
    params.append('otherId', otherId);

    return await axios({
      method: 'DELETE',
      url: `${API_URL}/api/worksheets/other-expense`,
      responseType: 'json',
      params,
    });
  }

  async getOneOtherExpense(otherId: string): Promise<OtherExpensesDto> {
    const params = new URLSearchParams();
    params.append('otherId', otherId);

    const response = await axios({
      method: 'GET',
      url: `${API_URL}/api/worksheets/other-expense`,
      responseType: 'json',
      params,
    });

    return response.data;
  }

  async createOtherExpenseAttachment(otherId: string, file: string) {
    const params = new URLSearchParams();
    params.append('otherId', otherId);

    return await axios({
      method: 'POST',
      url: `${API_URL}/api/worksheets/other-expense/binary`,
      headers: { 'Content-Type': 'multipart/form-data' },
      params,
      data: file,
    });
  }

  async getOtherExpenseAttachment(binaryId: string) {
    const params = new URLSearchParams();
    params.append('binaryId', binaryId);

    const response = await axios({
      method: 'GET',
      url: `${API_URL}/api/worksheets/other-expense/binary`,
      responseType: 'blob',
      params,
    });

    return response.data;
  }

  async deleteOtherExpenseAttachment(binaryId: string) {
    const params = new URLSearchParams();
    params.append('binaryId', binaryId);

    return await axios({
      method: 'DELETE',
      url: `${API_URL}/api/worksheets/other-expense/binary`,
      params,
    });
  }

  async getOtherExpenseAttachmentList(
    otherId: string
  ): Promise<OtherExpenseAttachmentDto[]> {
    const params = new URLSearchParams();
    params.append('otherId', otherId);

    const response = await axios({
      method: 'GET',
      url: `${API_URL}/api/worksheets/other-expense/binary/list`,
      params,
    });

    return response.data;
  }
}
