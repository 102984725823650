import { PlusOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { MouseEventHandler } from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  className?: string;
  onClick: MouseEventHandler<HTMLElement>;
}

const NewButton = (props: Props) => {
  const { t } = useTranslation();

  return (
    <Button
      className={props.className}
      icon={<PlusOutlined />}
      onClick={props.onClick}
    >
      {t('common:new')}
    </Button>
  );
};

export default NewButton;
