import {
  EllipsisOutlined,
  LogoutOutlined,
  PoweroffOutlined,
  QuestionCircleOutlined,
  SettingOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { Button, Divider, Dropdown, Flex } from 'antd';
import type { MenuProps } from 'antd';
import Sider from 'antd/es/layout/Sider';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, NavLink } from 'react-router-dom';

import { RoutesConstants } from '../../../constants/routes-constants';
import { AuthContext } from '../../../context/auth-context';
import LanguageSwitcher from './language-switcher.component';

const Sidebar = () => {
  const { user } = useContext(AuthContext);
  const { t } = useTranslation('sidebar');
  const { logout } = useContext(AuthContext);
  const [collapsed, setCollapsed] = useState(false);

  const items: MenuProps['items'] = [
    {
      key: '1',
      label: (
        <div className='logged-user'>
          <UserOutlined />
          <span>{user?.givenName + ' ' + user?.familyName}</span>
        </div>
      ),
      disabled: true,
    },
    {
      type: 'divider',
    },
    {
      key: '2',
      label: <LanguageSwitcher mobile={true} />,
    },
    {
      type: 'divider',
    },
    {
      key: '3',
      label: <Link to={'/settings'}>{t('settings')}</Link>,
      icon: <SettingOutlined />,
    },
    {
      type: 'divider',
    },
    {
      key: '4',
      label: <Link to={'/manual'}>{t('user-manual')}</Link>,
      icon: <QuestionCircleOutlined />,
    },
    {
      type: 'divider',
    },
    {
      key: '5',
      label: t('logout'),
      icon: <LogoutOutlined />,
      onClick: () => logout(),
      danger: true,
    },
  ];

  return (
    <>
      <Sider
        className='d-none d-lg-block'
        collapsed={collapsed}
        width={'20%'}
        onCollapse={(value) => setCollapsed(value)}
      >
        <div className='text-center my-5'>
          <img
            src={'/assets/aspira-uciliste-w.png'}
            alt='Logo'
            className='img-fluid mw-100'
          />
        </div>
        <div className='logged-user'>
          <UserOutlined />
          <span>{user?.givenName + ' ' + user?.familyName}</span>
        </div>
        <Divider />
        <Flex vertical>
          {RoutesConstants(user).map((route, index) => {
            if (route.visibleToUser && route.sidebar) {
              return (
                <NavLink key={index} to={route.path} className='main-nav'>
                  <span className='me-1'>{route.icon}</span>
                  {t(`${route.name}`)}
                </NavLink>
              );
            } else return undefined;
          })}
        </Flex>
        <div className='bottom-sidebar'>
          <LanguageSwitcher mobile={false} />
          <Button
            onClick={logout}
            className='logout-button mw-100'
            icon={<PoweroffOutlined />}
          >
            {t('logout')}
          </Button>
        </div>
      </Sider>
      <div className='d-lg-none mobile-menu'>
        {RoutesConstants(user).map((route, index) => {
          if (
            route.sidebar &&
            route.name !== 'settings' &&
            route.name !== 'user-manual'
          ) {
            return (
              <NavLink key={index} to={route.path} className='mobile-nav'>
                <div className='text-center'>{route.icon}</div>
                <div className='text-center fs-small'>{t(`${route.name}`)}</div>
              </NavLink>
            );
          } else return undefined;
        })}
        <Dropdown menu={{ items }} destroyPopupOnHide>
          <EllipsisOutlined className='mobile-nav' />
        </Dropdown>
      </div>
    </>
  );
};

export default Sidebar;
