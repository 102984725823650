import {
  ArrowLeftOutlined,
  ArrowRightOutlined,
  DeleteOutlined,
  DownloadOutlined,
  EditOutlined,
} from '@ant-design/icons';
import { PDFDownloadLink } from '@react-pdf/renderer';
import {
  Button,
  Empty,
  Flex,
  Popconfirm,
  Spin,
  Table,
  TableProps,
  Tooltip,
} from 'antd';
import dayjs from 'dayjs';
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';

import { Server } from '../../../../../../../api/server-index';
import {
  DailyWorkLogDto,
  MonthlyWorkLogDto,
} from '../../../../../../../types/dto/work-logs.dto';
import { ColumnType } from '../../../../../../../types/enum/wl-column-type';
import { DateUtils } from '../../../../../../../utils/date.utils';
import BackButton from '../../../../../../common/buttons/back-button.component';
import Subtitle from '../../../../../../common/titles/subtitle.component';
import BatchEditWorkLog from './batch-edit/batch-edit-work-log.component';
import MonthlyWorkLogPdf from './monthly-work-log-pdf/monthly-work-log-pdf.component';
import MonthlyWorkLogSummary from './monthly-work-log-summary/monthly-work-log-summary.component';

interface Props {
  monthlyWorkLogId: string;
  setMonthlyWorkLogId: Dispatch<SetStateAction<string>>;
}

const ViewMonthlyWorkLog = ({
  monthlyWorkLogId,
  setMonthlyWorkLogId,
}: Props) => {
  const { t } = useTranslation('work-logs');
  const [loading, setLoading] = useState<boolean>(true);
  const [reload, setReload] = useState<boolean>(false);
  const [monthlyWorkLog, setMonthlyWorkLog] = useState<MonthlyWorkLogDto>();
  const [dailyWorkLogs, setDailyWorkLogs] = useState<DailyWorkLogDto[]>([]);
  const [columnType, setColumnType] = useState<ColumnType>(ColumnType.PRESENCE);
  const [isBatchModalOpen, setIsBatchModalOpen] = useState<boolean>(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState<string[]>([]);

  const fetchMonthlyWorkLog = useCallback(async () => {
    setLoading(true);
    if (monthlyWorkLogId) {
      const response = await Server.WorkLogs.getMonthlyWorkLogById(
        monthlyWorkLogId
      );
      setMonthlyWorkLog(response);
    }
    setLoading(false);
  }, [monthlyWorkLogId]);

  const fetchDailyWorkLogs = useCallback(async () => {
    setLoading(true);
    if (monthlyWorkLogId) {
      const response = await Server.WorkLogs.getDailyWorkLogs(monthlyWorkLogId);
      setDailyWorkLogs(response);
    }
    setLoading(false);
  }, [monthlyWorkLogId]);

  useEffect(() => {
    fetchMonthlyWorkLog();
    fetchDailyWorkLogs();

    setReload(false);
  }, [fetchDailyWorkLogs, fetchMonthlyWorkLog, reload]);

  const renderTitle = (type: ColumnType) => {
    return (
      <Flex align='center' justify='space-between'>
        <Tooltip
          title={
            type === ColumnType.ABSENCE
              ? t(`view.columns.${ColumnType.PRESENCE}`)
              : ''
          }
        >
          <Button
            ghost
            icon={<ArrowLeftOutlined />}
            disabled={type === ColumnType.PRESENCE}
            onClick={() => setColumnType(ColumnType.PRESENCE)}
          />
        </Tooltip>

        <span>{t(`view.columns.${type}`).toUpperCase()}</span>
        <Tooltip
          title={
            type === ColumnType.PRESENCE
              ? t(`view.columns.${ColumnType.ABSENCE}`)
              : ''
          }
        >
          <Button
            ghost
            icon={<ArrowRightOutlined />}
            disabled={type === ColumnType.ABSENCE}
            onClick={() => setColumnType(ColumnType.ABSENCE)}
          />
        </Tooltip>
      </Flex>
    );
  };

  const columns: {
    presence: TableProps<DailyWorkLogDto>['columns'];
    absence: TableProps<DailyWorkLogDto>['columns'];
  } = {
    presence: [
      {
        key: 'day',
        fixed: 'left',
        width: '90px',
        align: 'center',
        title: t('view.columns.day'),
        dataIndex: 'day',
        render: (day) => {
          const month = monthlyWorkLog?.month;
          const year = monthlyWorkLog?.year;
          if (month && year)
            return (
              <span>{`${t(
                `view.days.${DateUtils.dayOfWeekShortname(day, month, year)}`
              )}, ${day}.${month}.`}</span>
            );
        },
      },
      {
        key: 'presence',
        title: renderTitle(ColumnType.PRESENCE),
        children: [
          {
            key: 'startTime',
            width: '85px',
            align: 'center',
            title: (
              <small className='rotated-text'>
                {t('view.columns.startTime')}
              </small>
            ),
            dataIndex: 'startTime',
            render: (startTime) =>
              startTime ? (
                <span>{dayjs(startTime, 'HH:mm').format('HH:mm')}</span>
              ) : null,
          },
          {
            key: 'endTime',
            width: '85px',
            align: 'center',
            title: (
              <small className='rotated-text'>
                {t('view.columns.endTime')}
              </small>
            ),
            dataIndex: 'endTime',
            render: (endTime) =>
              endTime ? (
                <span>{dayjs(endTime, 'HH:mm').format('HH:mm')}</span>
              ) : null,
          },
          {
            key: 'dailyWorkHours',
            width: '85px',
            align: 'center',
            title: (
              <small className='rotated-text'>
                {t('view.columns.dailyWorkHours')}
              </small>
            ),
            dataIndex: 'dailyWorkHours',
            render: (dailyWorkHours) =>
              dailyWorkHours ? (
                <span>{Number(dailyWorkHours).toFixed(0)}</span>
              ) : (
                ''
              ),
          },
          {
            key: 'dailyBreakMinutes',
            width: '85px',
            align: 'center',
            title: (
              <small className='rotated-text'>
                {t('view.columns.dailyBreakMinutes')}
              </small>
            ),
            dataIndex: 'dailyBreakMinutes',
            render: (dailyBreakMinutes) =>
              dailyBreakMinutes ? (
                <span>{Number(dailyBreakMinutes).toFixed(0)}</span>
              ) : (
                ''
              ),
          },
          {
            key: 'nightWorkHours',
            width: '85px',
            align: 'center',
            title: (
              <small className='rotated-text'>
                {t('view.columns.nightWorkHours')}
              </small>
            ),
            dataIndex: 'nightWorkHours',
            render: (nightWorkHours) =>
              nightWorkHours ? (
                <span>{Number(nightWorkHours).toFixed(0)}</span>
              ) : (
                ''
              ),
          },
          {
            key: 'overtimeHours',
            width: '85px',
            align: 'center',
            title: (
              <small className='rotated-text'>
                {t('view.columns.overtimeHours')}
              </small>
            ),
            dataIndex: 'overtimeHours',
            render: (overtimeHours) =>
              overtimeHours ? (
                <span>{Number(overtimeHours).toFixed(0)}</span>
              ) : (
                ''
              ),
          },
          {
            key: 'holidayWorkHours',
            width: '85px',
            align: 'center',
            title: (
              <small className='rotated-text'>
                {t('view.columns.holidayWorkHours')}
              </small>
            ),
            dataIndex: 'holidayWorkHours',
            render: (holidayWorkHours) =>
              holidayWorkHours ? (
                <span>{Number(holidayWorkHours).toFixed(0)}</span>
              ) : (
                ''
              ),
          },
          {
            key: 'businessTripHours',
            width: '85px',
            align: 'center',
            title: (
              <small className='rotated-text'>
                {t('view.columns.businessTripHours')}
              </small>
            ),
            dataIndex: 'businessTripHours',
            render: (businessTripHours) =>
              businessTripHours ? (
                <span>{Number(businessTripHours).toFixed(0)}</span>
              ) : (
                ''
              ),
          },
          {
            key: 'standbyHours',
            width: '85px',
            align: 'center',
            title: (
              <small className='rotated-text'>
                {t('view.columns.standbyHours')}
              </small>
            ),
            dataIndex: 'standbyHours',
            render: (standbyHours) =>
              standbyHours ? (
                <span>{Number(standbyHours).toFixed(0)}</span>
              ) : (
                ''
              ),
          },
        ],
      },
    ],
    absence: [
      {
        key: 'day',
        fixed: 'left',
        width: '90px',
        align: 'center',
        title: t('view.columns.day'),
        dataIndex: 'day',
        render: (day) => {
          const month = monthlyWorkLog?.month;
          const year = monthlyWorkLog?.year;
          if (month && year)
            return (
              <span>{`${t(
                `view.days.${DateUtils.dayOfWeekShortname(day, month, year)}`
              )}, ${day}.${month}.`}</span>
            );
        },
      },
      {
        key: 'absence',
        title: renderTitle(ColumnType.ABSENCE),
        children: [
          {
            key: 'weeklyLeaveHours',
            width: '50px',
            align: 'center',
            title: (
              <small className='rotated-text'>
                {t('view.columns.weeklyLeaveHours')}
              </small>
            ),
            dataIndex: 'weeklyLeaveHours',
            render: (weeklyLeaveHours) =>
              weeklyLeaveHours ? (
                <span>{Number(weeklyLeaveHours).toFixed(0)}</span>
              ) : (
                ''
              ),
          },
          {
            key: 'holidayHours',
            width: '50px',
            align: 'center',
            title: (
              <small className='rotated-text'>
                {t('view.columns.holidayHours')}
              </small>
            ),
            dataIndex: 'holidayHours',
            render: (holidayHours) =>
              holidayHours ? (
                <span>{Number(holidayHours).toFixed(0)}</span>
              ) : (
                ''
              ),
          },
          {
            key: 'annualLeaveHours',
            width: '50px',
            align: 'center',
            title: (
              <small className='rotated-text'>
                {t('view.columns.annualLeaveHours')}
              </small>
            ),
            dataIndex: 'annualLeaveHours',
            render: (annualLeaveHours) =>
              annualLeaveHours ? (
                <span>{Number(annualLeaveHours).toFixed(0)}</span>
              ) : (
                ''
              ),
          },
          {
            key: 'shortTermMedicalLeaveHours',
            width: '50px',
            align: 'center',
            title: (
              <small className='rotated-text'>
                {t('view.columns.shortTermMedicalLeaveHours')}
              </small>
            ),
            dataIndex: 'shortTermMedicalLeaveHours',
            render: (shortTermMedicalLeaveHours) =>
              shortTermMedicalLeaveHours ? (
                <span>{Number(shortTermMedicalLeaveHours).toFixed(0)}</span>
              ) : (
                ''
              ),
          },
          {
            key: 'longTermMedicalLeaveHours',
            width: '50px',
            align: 'center',
            title: (
              <small className='rotated-text'>
                {t('view.columns.longTermMedicalLeaveHours')}
              </small>
            ),
            dataIndex: 'longTermMedicalLeaveHours',
            render: (longTermMedicalLeaveHours) =>
              longTermMedicalLeaveHours ? (
                <span>{Number(longTermMedicalLeaveHours).toFixed(0)}</span>
              ) : (
                ''
              ),
          },
          {
            key: 'parentalLeaveHours',
            width: '50px',
            align: 'center',
            title: (
              <small className='rotated-text'>
                {t('view.columns.parentalLeaveHours')}
              </small>
            ),
            dataIndex: 'parentalLeaveHours',
            render: (parentalLeaveHours) =>
              parentalLeaveHours ? (
                <span>{Number(parentalLeaveHours).toFixed(0)}</span>
              ) : (
                ''
              ),
          },
          {
            key: 'paidLeaveHours',
            width: '50px',
            align: 'center',
            title: (
              <small className='rotated-text'>
                {t('view.columns.paidLeaveHours')}
              </small>
            ),
            dataIndex: 'paidLeaveHours',
            render: (paidLeaveHours) =>
              paidLeaveHours ? (
                <span>{Number(paidLeaveHours).toFixed(0)}</span>
              ) : (
                ''
              ),
          },
          {
            key: 'unpaidLeaveHours',
            width: '50px',
            align: 'center',
            title: (
              <small className='rotated-text'>
                {t('view.columns.unpaidLeaveHours')}
              </small>
            ),
            dataIndex: 'unpaidLeaveHours',
            render: (unpaidLeaveHours) =>
              unpaidLeaveHours ? (
                <span>{Number(unpaidLeaveHours).toFixed(0)}</span>
              ) : (
                ''
              ),
          },
          {
            key: 'requestedAbsenceHours',
            width: '70px',
            align: 'center',
            title: (
              <small className='rotated-text'>
                {t('view.columns.requestedAbsenceHours')}
              </small>
            ),
            dataIndex: 'requestedAbsenceHours',
            render: (requestedAbsenceHours) =>
              requestedAbsenceHours ? (
                <span>{Number(requestedAbsenceHours).toFixed(0)}</span>
              ) : (
                ''
              ),
          },
          {
            key: 'employerCausedAbsenceHours',
            width: '70px',
            align: 'center',
            title: (
              <small className='rotated-text'>
                {t('view.columns.employerCausedAbsenceHours')}
              </small>
            ),
            dataIndex: 'employerCausedAbsenceHours',
            render: (employerCausedAbsenceHours) =>
              employerCausedAbsenceHours ? (
                <span>{Number(employerCausedAbsenceHours).toFixed(0)}</span>
              ) : (
                ''
              ),
          },
          {
            key: 'employeeCausedAbsenceHours',
            width: '70px',
            align: 'center',
            title: (
              <small className='rotated-text'>
                {t('view.columns.employeeCausedAbsenceHours')}
              </small>
            ),
            dataIndex: 'employeeCausedAbsenceHours',
            render: (employeeCausedAbsenceHours) =>
              employeeCausedAbsenceHours ? (
                <span>{Number(employeeCausedAbsenceHours).toFixed(0)}</span>
              ) : (
                ''
              ),
          },
          {
            key: 'strikeHours',
            width: '40px',
            align: 'center',
            title: (
              <small className='rotated-text'>
                {t('view.columns.strikeHours')}
              </small>
            ),
            dataIndex: 'strikeHours',
            render: (strikeHours) =>
              strikeHours ? <span>{Number(strikeHours).toFixed(0)}</span> : '',
          },
          {
            key: 'lockoutHours',
            width: '50px',
            align: 'center',
            title: (
              <small className='rotated-text'>
                {t('view.columns.lockoutHours')}
              </small>
            ),
            dataIndex: 'lockoutHours',
            render: (lockoutHours) =>
              lockoutHours ? (
                <span>{Number(lockoutHours).toFixed(0)}</span>
              ) : (
                ''
              ),
          },
          {
            key: 'militaryLeaveHours',
            width: '70px',
            align: 'center',
            title: (
              <small className='rotated-text'>
                {t('view.columns.militaryLeaveHours')}
              </small>
            ),
            dataIndex: 'militaryLeaveHours',
            render: (militaryLeaveHours) =>
              militaryLeaveHours ? (
                <span>{Number(militaryLeaveHours).toFixed(0)}</span>
              ) : (
                ''
              ),
          },
        ],
      },
    ],
  };

  const handleDeleteWorkLog = async () => {
    try {
      await Server.WorkLogs.deleteMonthlyWorkLog(monthlyWorkLogId);
      setMonthlyWorkLogId('');
    } catch (error) {
      return error;
    }
  };

  return (
    <Spin spinning={loading}>
      <BackButton onClick={() => setMonthlyWorkLogId('')} />
      <Flex justify='space-between' align='center'>
        <Flex gap={6}>
          {monthlyWorkLog ? (
            <i>
              <Subtitle
                text={`${t(
                  `view.months.${DateUtils.getMonthName(monthlyWorkLog.month)}`
                ).toUpperCase()} ${monthlyWorkLog.year}.`}
              />
            </i>
          ) : (
            <></>
          )}
        </Flex>
        <Flex gap={6}>
          {selectedRowKeys.length > 0 ? (
            <Button
              className={`batch-edit-button ${
                selectedRowKeys.length > 0 ? 'visible' : 'hidden'
              }`}
              onClick={() => setIsBatchModalOpen(true)}
            >
              <EditOutlined />
            </Button>
          ) : (
            <></>
          )}
          {monthlyWorkLog ? (
            <PDFDownloadLink
              className='ant-btn css-dev-only-do-not-override-33o72l ant-btn-primary'
              fileName={`EVID_${monthlyWorkLog?.month}/${monthlyWorkLog?.year}_${monthlyWorkLog?.employeePin}`}
              document={
                <MonthlyWorkLogPdf
                  monthlyWorkLog={monthlyWorkLog}
                  dailyWorkLogs={dailyWorkLogs}
                />
              }
            >
              {({ blob, loading, url, error }) =>
                loading ? (
                  <Button>
                    <DownloadOutlined />
                  </Button>
                ) : (
                  <Button>
                    <DownloadOutlined />
                  </Button>
                )
              }
            </PDFDownloadLink>
          ) : (
            <></>
          )}
          <Popconfirm
            title={t('delete.title')}
            okText={t('delete.confirm')}
            onConfirm={handleDeleteWorkLog}
          >
            <Button danger>
              <DeleteOutlined />
            </Button>
          </Popconfirm>
        </Flex>
      </Flex>
      <Table
        className='mb-2'
        rowKey={(val) => val.id}
        rowClassName={(record) => {
          if (
            monthlyWorkLog &&
            DateUtils.isWeekendOrHoliday(
              record.day,
              monthlyWorkLog?.month,
              monthlyWorkLog?.year
            )
          ) {
            return 'weekend-row';
          } else return '';
        }}
        bordered
        size='small'
        rowSelection={{
          type: 'checkbox',
          columnWidth: '40px',
          selectedRowKeys,
          onSelect: (record, selected) => {
            if (selected) {
              setSelectedRowKeys((prev) => [...prev, record.id]);
            } else {
              setSelectedRowKeys((prev) =>
                prev.filter((key) => key !== record.id)
              );
            }
          },
          onSelectAll: () => {
            if (monthlyWorkLog) {
              const workDayRows = dailyWorkLogs
                .filter(
                  (row) =>
                    !DateUtils.isWeekendOrHoliday(
                      row.day,
                      monthlyWorkLog?.month,
                      monthlyWorkLog?.year
                    )
                )
                .map((row) => row.id);

              const areAllSelected = workDayRows.every((id) =>
                selectedRowKeys.includes(id)
              );

              if (areAllSelected) {
                setSelectedRowKeys([]);
              } else {
                setSelectedRowKeys(workDayRows);
              }
            }
          },
        }}
        columns={columns[columnType]}
        dataSource={dailyWorkLogs}
        loading={loading}
        pagination={false}
        scroll={{ x: 800 }}
        locale={{
          emptyText: (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description={t('common:no_data')}
            />
          ),
        }}
      />
      {monthlyWorkLog ? (
        <MonthlyWorkLogSummary monthlyWorkLog={monthlyWorkLog} />
      ) : (
        <></>
      )}

      {selectedRowKeys.length > 0 ? (
        <Button
          className={`floating-edit-button ${
            selectedRowKeys.length > 0 ? 'visible' : 'hidden'
          }`}
          onClick={() => setIsBatchModalOpen(true)}
          icon={<EditOutlined />}
        >
          {t('edit.selected')}
        </Button>
      ) : (
        <></>
      )}
      <BatchEditWorkLog
        isBatchModalOpen={isBatchModalOpen}
        setIsBatchModalOpen={setIsBatchModalOpen}
        setReload={setReload}
        selectedRowKeys={selectedRowKeys}
        monthlyWorkLogId={monthlyWorkLogId}
        setSelectedRowKeys={setSelectedRowKeys}
      />
    </Spin>
  );
};

export default ViewMonthlyWorkLog;
