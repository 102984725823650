import { QuestionCircleFilled } from '@ant-design/icons';
import { Button, Card, Col, Flex, Tooltip, notification } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { Server } from '../../../../../../api/server-index';

const ChangePassword = () => {
  const { t } = useTranslation('settings');

  const sendChangePasswordEmail = async () => {
    const response = await Server.User.changePassword();
    if (response.status === 200) {
      notification.success({ message: t('password.change.success') });
    }
  };

  return (
    <Col xl={12} lg={12} md={24} sm={24} xs={24}>
      <Card className='shadow-sm'>
        <Flex gap={8}>
          <h4>{t('password.change.title')}</h4>
          <Tooltip title={t('password.change.tooltip')}>
            <QuestionCircleFilled />
          </Tooltip>
        </Flex>
        <Flex justify='end'>
          <Button
            onClick={() => sendChangePasswordEmail()}
            type='primary'
            ghost
          >
            {t('password.change.send')}
          </Button>
        </Flex>
      </Card>
    </Col>
  );
};

export default ChangePassword;
