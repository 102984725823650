interface SubtitleProps {
  className?: string;
  text: string;
}

const Subtitle = (props: SubtitleProps) => {
  return <h3 className={props.className}>{props.text}</h3>;
};

export default Subtitle;
