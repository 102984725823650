import {
  CheckOutlined,
  CloseOutlined,
  DeleteOutlined,
  EditOutlined,
} from '@ant-design/icons';
import {
  Button,
  Empty,
  Flex,
  Modal,
  Popconfirm,
  Table,
  TablePaginationConfig,
  TableProps,
  Tooltip,
  notification,
} from 'antd';
import TextArea from 'antd/es/input/TextArea';
import dayjs from 'dayjs';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Server } from '../../../../../../../api/server-index';
import { AuthContext } from '../../../../../../../context/auth-context';
import { PaginatedData } from '../../../../../../../types/dto/common.dto';
import {
  WorksheetDto,
  WorksheetNoteCreateUpdateDto,
  WorksheetNoteDto,
} from '../../../../../../../types/dto/worksheet.dto';
import NewButton from '../../../../../../common/buttons/new-button.component';

interface WorksheetNotesProps {
  worksheet: WorksheetDto;
}

const WorksheetNotes = (props: WorksheetNotesProps) => {
  const { worksheet } = props;
  const { user } = useContext(AuthContext);
  const { t } = useTranslation('worksheets');
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [reload, setReload] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const [notes, setNotes] = useState<PaginatedData<WorksheetNoteDto[]>>();
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [newNote, setNewNote] = useState<WorksheetNoteCreateUpdateDto>({
    note: '',
  });

  const [editingNoteId, setEditingNoteId] = useState<string>();
  const [editData, setEditData] = useState<WorksheetNoteCreateUpdateDto>();

  const fetchWorksheetNotes = useCallback(async () => {
    setLoading(true);
    const response = await Server.Worksheets.getNotes({
      page: currentPage,
      pageSize: 10,
      worksheetId: worksheet.id,
    });
    setNotes(response);
  }, [currentPage, worksheet.id]);

  useEffect(() => {
    fetchWorksheetNotes();
    setReload(false);
    setLoading(false);
  }, [fetchWorksheetNotes, reload]);

  const createNewNote = async () => {
    setLoading(true);
    try {
      const response = await Server.Worksheets.createNote(
        worksheet.id,
        newNote
      );

      if (response.status === 201) {
        notification.success({ message: t('edit.notes.new.success') });
        setIsModalOpen(false);
        setNewNote({ note: '' });
        setReload(true);
      }
    } catch (error) {}

    setLoading(false);
  };

  const deleteNote = async (id: string) => {
    setLoading(true);

    const response = await Server.Worksheets.deleteNote(id);

    if (response.status === 200) {
      notification.success({ message: t('edit.notes.options.success_delete') });
      setReload(true);
    }

    setLoading(false);
  };

  const updateNote = async (id: string) => {
    setLoading(true);

    if (editData) {
      const response = await Server.Worksheets.updateNote(id, editData);

      if (response.status === 200) {
        notification.success({
          message: t('edit.notes.options.success_update'),
        });
        setEditingNoteId('');
        setReload(true);
      }
    }

    setLoading(false);
  };

  const handlePageChange = (pagination: TablePaginationConfig) => {
    setCurrentPage(pagination.current || 1);
  };

  const notesColumns: TableProps<WorksheetNoteDto>['columns'] = [
    {
      key: 'createDate',
      title: t('edit.notes.created_at'),
      dataIndex: 'createDate',
      width: '30%',
      render: (date) => dayjs(date).format('DD.MM.YYYY. HH:mm'),
    },
    {
      key: 'note',
      title: t('edit.notes.note'),
      dataIndex: 'note',
      width: '40%',
      render: (text, record) => {
        if (editingNoteId === record.id) {
          return (
            <TextArea
              maxLength={240}
              showCount
              defaultValue={text}
              onChange={(e) => setEditData({ note: e.target.value })}
            />
          );
        }

        return <span>{text}</span>;
      },
    },
    {
      key: 'author',
      title: t('edit.notes.author'),
      dataIndex: 'author',
      width: '20%',
      render: (author) => (
        <span>{author.givenName + ' ' + author.familyName}</span>
      ),
    },
    {
      key: 'options',
      title: t('edit.notes.options.title'),
      dataIndex: 'id',
      width: '10%',
      render: (id, record) => {
        return user?.email === record.author.email ? (
          <Flex gap={12}>
            {editingNoteId === record.id ? (
              <>
                <Tooltip title={t('edit.expenses.actions.popconfirm.cancel')}>
                  <Button
                    onClick={() => {
                      setEditingNoteId('');
                    }}
                  >
                    <CloseOutlined />
                  </Button>
                </Tooltip>
                <Tooltip title={t('edit.expenses.actions.popconfirm.ok')}>
                  <Button loading={loading} onClick={() => updateNote(id)}>
                    <CheckOutlined />
                  </Button>
                </Tooltip>
              </>
            ) : (
              <Tooltip title={t('edit.notes.options.edit')}>
                {' '}
                <Button onClick={() => setEditingNoteId(record.id)}>
                  <EditOutlined />
                </Button>
              </Tooltip>
            )}
            <Popconfirm
              title={t('edit.notes.options.popconfirm.title')}
              onConfirm={() => deleteNote(id)}
              okText={t('edit.notes.options.popconfirm.ok')}
              cancelText={t('edit.notes.options.popconfirm.cancel')}
            >
              <Button loading={loading} danger>
                <DeleteOutlined />
              </Button>
            </Popconfirm>
          </Flex>
        ) : (
          <></>
        );
      },
    },
  ];

  return (
    <div>
      <Flex gap={12} align='center'>
        <NewButton className='mb-1' onClick={() => setIsModalOpen(true)} />
      </Flex>
      <Table
        dataSource={notes?.records}
        columns={notesColumns}
        scroll={{ x: 800 }}
        pagination={{
          total: notes?.totalCount,
          pageSize: 10,
          hideOnSinglePage: true,
        }}
        onChange={handlePageChange}
        locale={{
          emptyText: (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description={t('common:no_data')}
            />
          ),
        }}
      />
      <Modal
        open={isModalOpen}
        onCancel={() => {
          setIsModalOpen(false);
          setNewNote({ note: '' });
        }}
        onOk={() => createNewNote()}
        okButtonProps={{ ghost: true }}
        okText={t('edit.notes.new.confirm')}
        cancelText={t('edit.notes.new.cancel')}
        confirmLoading={loading}
      >
        <h3>{t('edit.notes.new.title')}</h3>
        <TextArea
          className='mb-1'
          maxLength={240}
          showCount
          value={newNote.note}
          onChange={(e) => setNewNote({ note: e.target.value })}
        />
      </Modal>
    </div>
  );
};

export default WorksheetNotes;
