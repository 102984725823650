import axios from 'axios';

import { API_URL } from '../../constants/constants';
import { SubjectDto } from '../../types/dto/subject.dto';

export class Subjects {
  async getSubjectsForLecturer(academicYear: number): Promise<SubjectDto[]> {
    const params = new URLSearchParams();
    params.append('academicYear', academicYear.toString());

    const response = await axios({
      method: 'GET',
      responseType: 'json',
      url: `${API_URL}/api/subject`,
      params,
    });
    return response.data;
  }

  async getSubjectById(subjectId: string): Promise<SubjectDto> {
    const params = new URLSearchParams();
    params.append('subjectId', subjectId);

    const response = await axios({
      method: 'GET',
      url: `${API_URL}/api/subject/id`,
      responseType: 'json',
      params,
    });

    return response.data;
  }
}
