import { Col, Form, InputNumber, Row } from 'antd';
import { useTranslation } from 'react-i18next';

const AbsenceForm = () => {
  const { t } = useTranslation('work-logs');

  return (
    <Row gutter={16}>
      <Col xs={24} sm={24} md={24} lg={12}>
        <Form.Item
          label={t('view.columns.weeklyLeaveHours')}
          name='weeklyLeaveHours'
        >
          <InputNumber
            min={0}
            max={24}
            step={0.5}
            className='w-100'
            addonAfter={'h'}
          />
        </Form.Item>
      </Col>
      <Col xs={24} sm={24} md={24} lg={12}>
        <Form.Item label={t('view.columns.holidayHours')} name='holidayHours'>
          <InputNumber
            min={0}
            max={24}
            step={0.5}
            className='w-100'
            addonAfter={'h'}
          />
        </Form.Item>
      </Col>
      <Col xs={24} sm={24} md={24} lg={12}>
        <Form.Item
          label={t('view.columns.annualLeaveHours')}
          name='annualLeaveHours'
        >
          <InputNumber
            min={0}
            max={24}
            step={0.5}
            className='w-100'
            addonAfter={'h'}
          />
        </Form.Item>
      </Col>
      <Col xs={24} sm={24} md={24} lg={12}>
        <Form.Item
          label={t('view.columns.shortTermMedicalLeaveHours')}
          name='shortTermMedicalLeaveHours'
        >
          <InputNumber
            min={0}
            max={24}
            step={0.5}
            className='w-100'
            addonAfter={'h'}
          />
        </Form.Item>
      </Col>
      <Col xs={24} sm={24} md={24} lg={12}>
        <Form.Item
          label={t('view.columns.longTermMedicalLeaveHours')}
          name='longTermMedicalLeaveHours'
        >
          <InputNumber
            min={0}
            max={24}
            step={0.5}
            className='w-100'
            addonAfter={'h'}
          />
        </Form.Item>
      </Col>
      <Col xs={24} sm={24} md={24} lg={12}>
        <Form.Item
          label={t('view.columns.parentalLeaveHours')}
          name='parentalLeaveHours'
        >
          <InputNumber
            min={0}
            max={24}
            step={0.5}
            className='w-100'
            addonAfter={'h'}
          />
        </Form.Item>
      </Col>
      <Col xs={24} sm={24} md={24} lg={12}>
        <Form.Item
          label={t('view.columns.paidLeaveHours')}
          name='paidLeaveHours'
        >
          <InputNumber
            min={0}
            max={24}
            step={0.5}
            className='w-100'
            addonAfter={'h'}
          />
        </Form.Item>
      </Col>
      <Col xs={24} sm={24} md={24} lg={12}>
        <Form.Item
          label={t('view.columns.unpaidLeaveHours')}
          name='unpaidLeaveHours'
        >
          <InputNumber
            min={0}
            max={24}
            step={0.5}
            className='w-100'
            addonAfter={'h'}
          />
        </Form.Item>
      </Col>
      <Col xs={24} sm={24} md={24} lg={12}>
        <Form.Item
          label={t('view.columns.requestedAbsenceHours')}
          name='requestedAbsenceHours'
        >
          <InputNumber
            min={0}
            max={24}
            step={0.5}
            className='w-100'
            addonAfter={'h'}
          />
        </Form.Item>
      </Col>
      <Col xs={24} sm={24} md={24} lg={12}>
        <Form.Item
          label={t('view.columns.employerCausedAbsenceHours')}
          name='employerCausedAbsenceHours'
        >
          <InputNumber
            min={0}
            max={24}
            step={0.5}
            className='w-100'
            addonAfter={'h'}
          />
        </Form.Item>
      </Col>
      <Col xs={24} sm={24} md={24} lg={12}>
        <Form.Item
          label={t('view.columns.employeeCausedAbsenceHours')}
          name='employeeCausedAbsenceHours'
        >
          <InputNumber
            min={0}
            max={24}
            step={0.5}
            className='w-100'
            addonAfter={'h'}
          />
        </Form.Item>
      </Col>
      <Col xs={24} sm={24} md={24} lg={12}>
        <Form.Item label={t('view.columns.strikeHours')} name='strikeHours'>
          <InputNumber
            min={0}
            max={24}
            step={0.5}
            className='w-100'
            addonAfter={'h'}
          />
        </Form.Item>
      </Col>
      <Col xs={24} sm={24} md={24} lg={12}>
        <Form.Item label={t('view.columns.lockoutHours')} name='lockoutHours'>
          <InputNumber
            min={0}
            max={24}
            step={0.5}
            className='w-100'
            addonAfter={'h'}
          />
        </Form.Item>
      </Col>
      <Col xs={24} sm={24} md={24} lg={12}>
        <Form.Item
          label={t('view.columns.militaryLeaveHours')}
          name='militaryLeaveHours'
        >
          <InputNumber
            min={0}
            max={24}
            step={0.5}
            className='w-100'
            addonAfter={'h'}
          />
        </Form.Item>
      </Col>
    </Row>
  );
};

export default AbsenceForm;
