import './index';
import { AnnualLeaveRequests } from './server-subclasses/annual-leave-requests';
import { Auth } from './server-subclasses/auth';
import { BusinessTripRequests } from './server-subclasses/business-trip-requests';
import { Compensation } from './server-subclasses/compensation';
import { EventCheckIn } from './server-subclasses/event-check-in';
import { Events } from './server-subclasses/events';
import { Partner } from './server-subclasses/partner';
import { Programs } from './server-subclasses/programs';
import { Subjects } from './server-subclasses/subjects';
import { User } from './server-subclasses/user';
import { WorkLogs } from './server-subclasses/work-logs';
import { Worksheets } from './server-subclasses/worksheets';

export class Server {
  static Auth: Auth = new Auth();
  static User: User = new User();
  static Worksheets: Worksheets = new Worksheets();
  static Partner: Partner = new Partner();
  static Events: Events = new Events();
  static EventCheckIn: EventCheckIn = new EventCheckIn();
  static Compensation: Compensation = new Compensation();
  static Programs: Programs = new Programs();
  static Subjects: Subjects = new Subjects();
  static WorkLogs: WorkLogs = new WorkLogs();
  static BusinessTripRequests: BusinessTripRequests =
    new BusinessTripRequests();
  static AnnualLeaveRequests: AnnualLeaveRequests = new AnnualLeaveRequests();
}
