import { View, Text, StyleSheet } from '@react-pdf/renderer';
import { useTranslation } from 'react-i18next';

import { MonthlyWorkLogDto } from '../../../../../../../../../types/dto/work-logs.dto';

interface Props {
  monthlyWorkLog: MonthlyWorkLogDto;
}

const MonthlyWorkLogPDFInfo = ({ monthlyWorkLog }: Props) => {
  const { t } = useTranslation('work-logs');

  const styles = StyleSheet.create({
    wrapper: {
      fontFamily: 'Roboto',
      padding: '16px 30px 10px 30px',
    },
    infoLine: {
      fontSize: 11,
      fontWeight: 400,
    },
  });

  return (
    <View style={styles.wrapper}>
      <Text style={styles.infoLine}>
        {t('btr.pdf.employee_full_name')}: {monthlyWorkLog.employeeName}
      </Text>
      <Text style={styles.infoLine}>OIB: {monthlyWorkLog.employeePin}</Text>
      <Text style={styles.infoLine}>
        Period: {`${monthlyWorkLog.month}/${monthlyWorkLog.year}`}
      </Text>
    </View>
  );
};

export default MonthlyWorkLogPDFInfo;
