interface MainTitleProps {
  className?: string;
  text: string;
}

const MainTitle = (props: MainTitleProps) => {
  return <h2 className={props.className}>{props.text}</h2>;
};

export default MainTitle;
