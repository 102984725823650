import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { Button, DatePicker, Flex, notification } from 'antd';
import dayjs from 'dayjs';
import { Dispatch, SetStateAction, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Server } from '../../../../../../../api/server-index';
import NewButton from '../../../../../../common/buttons/new-button.component';

interface Props {
  setReload: Dispatch<SetStateAction<boolean>>;
}

const NewMonthlyWorkLog = (props: Props) => {
  const { t } = useTranslation('work-logs');
  const { setReload } = props;
  const [period, setPeriod] = useState<{ month: number; year: number }>();
  const [active, setActive] = useState<boolean>(false);

  const createMonthlyWorklog = async () => {
    if (period) {
      const response = await Server.WorkLogs.createMonthlyWorkLog(period);
      if (response.status === 201) {
        notification.success({ message: t('new.success') });
        setPeriod(undefined);
        setReload(true);
        setActive(false);
      }
    }
  };

  const handleMonthChange = (date: any) => {
    if (date) {
      const selectedMonth = date.month() + 1;
      const selectedYear = date.year();
      setPeriod({
        month: selectedMonth,
        year: selectedYear,
      });
    }
  };

  return active ? (
    <Flex gap={8}>
      <DatePicker.MonthPicker
        placeholder={t('period')}
        format={'M/YYYY'}
        value={
          period?.month !== undefined && period?.year !== undefined
            ? dayjs(`${period.year}-${period.month}`, 'YYYY-M')
            : null
        }
        onChange={handleMonthChange}
      />
      <Button onClick={createMonthlyWorklog}>
        <CheckOutlined />
      </Button>
      <Button danger onClick={() => setActive(false)}>
        <CloseOutlined />
      </Button>
    </Flex>
  ) : (
    <NewButton onClick={() => setActive(true)} />
  );
};

export default NewMonthlyWorkLog;
