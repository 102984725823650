import { QuestionCircleFilled } from '@ant-design/icons';
import {
  Alert,
  Button,
  Card,
  Col,
  Divider,
  Flex,
  Form,
  Input,
  Row,
  Tooltip,
  notification,
} from 'antd';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Server } from '../../../../../../api/server-index';
import { AuthContext } from '../../../../../../context/auth-context';
import { formItemLayout } from '../../../../form/form-item.layout';

const ChangeSecurityPin = () => {
  const navigate = useNavigate();
  const { t } = useTranslation('settings');
  const { securityPin, checkSecurityPin, refreshUser } =
    useContext(AuthContext);

  const submitNewPIN = async (values: any) => {
    const response = await Server.User.updateSecurityPin(values.securityPin);
    if (response.status === 200) {
      notification.success({ message: t('security_pin.form.success') });
      refreshUser();
      navigate(-1);
    }
  };

  const sendResetSecurityPin = async () => {
    const response = await Server.User.sendResetSecurityPinEmail();
    if (response.status === 200) {
      notification.success({ message: t('security_pin.reset.success') });
    }
  };

  return (
    <>
      {!securityPin ? (
        <Alert
          closable
          showIcon
          description={t('security_pin.set_alert')}
          className='mb-1'
        />
      ) : (
        <></>
      )}
      <Row>
        <Col span={24}>
          <Card className='shadow-sm'>
            <h4>
              {securityPin
                ? t('security_pin.title_update')
                : t('security_pin.title_set')}
            </h4>
            <Form onFinish={submitNewPIN} {...formItemLayout}>
              {securityPin ? (
                <Form.Item
                  label={t('security_pin.form.old_pin')}
                  name={'oldPin'}
                  rules={[
                    {
                      required: true,
                      message: t('security_pin.form.validate.required'),
                    },
                    () => ({
                      validator(_, value) {
                        if (!value || checkSecurityPin(value)) {
                          return Promise.resolve();
                        }
                        return Promise.reject(
                          new Error(t('security_pin.form.validate.invalid_old'))
                        );
                      },
                    }),
                  ]}
                  validateTrigger='onSubmit'
                >
                  <Input.Password autoComplete='off' />
                </Form.Item>
              ) : (
                <></>
              )}
              <Form.Item
                label={t('security_pin.form.new_pin')}
                name={'securityPin'}
                rules={[
                  {
                    required: true,
                    message: t('security_pin.form.validate.required'),
                  },
                ]}
                validateTrigger='onSubmit'
              >
                <Input.Password autoComplete='off' />
              </Form.Item>
              <Form.Item
                label={t('security_pin.form.confirm_pin')}
                name={'confirmPin'}
                dependencies={['securityPin']}
                rules={[
                  {
                    required: true,
                    message: t('security_pin.form.validate.required'),
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue('securityPin') === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        new Error(t('security_pin.form.validate.no_match'))
                      );
                    },
                  }),
                ]}
                validateTrigger='onSubmit'
              >
                <Input.Password autoComplete='off' />
              </Form.Item>
              <Flex justify='flex-end'>
                <Button htmlType='submit' type='primary' ghost>
                  {t('security_pin.form.submit')}
                </Button>
              </Flex>
            </Form>
            <Divider />
            <Flex align='center' justify='space-between'>
              <Flex gap={8}>
                <h4>{t('security_pin.reset.title')}</h4>
                <Tooltip title={t('security_pin.reset.tooltip')}>
                  <QuestionCircleFilled />
                </Tooltip>
              </Flex>
              <Button
                onClick={() => sendResetSecurityPin()}
                type='primary'
                ghost
              >
                {t('password.change.send')}
              </Button>
            </Flex>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default ChangeSecurityPin;
