import {
  Button,
  Col,
  DatePicker,
  Flex,
  Form,
  Input,
  InputNumber,
  Modal,
  notification,
  Row,
  Select,
} from 'antd';
import { useForm } from 'antd/es/form/Form';
import { Dispatch, SetStateAction, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Server } from '../../../../../../../api/server-index';
import { CreateBusinessTripRequestDto } from '../../../../../../../types/dto/work-logs.dto';
import { VehicleType } from '../../../../../../../types/enum/vehicle-type';
import MainTitle from '../../../../../../common/titles/main-title.component';

interface Props {
  isNewModalOpen: boolean;
  setIsNewModalOpen: Dispatch<SetStateAction<boolean>>;
  setReload: Dispatch<SetStateAction<boolean>>;
}

const NewBusinessTripRequest = (props: Props) => {
  const [form] = useForm<CreateBusinessTripRequestDto>();
  const { t } = useTranslation('work-logs');
  const [loading, setLoading] = useState<boolean>(false);

  const handleCreateNewRequest = async () => {
    const formValues = form.getFieldsValue();

    try {
      setLoading(true);
      const response =
        await Server.BusinessTripRequests.createBusinessTripRequest(formValues);
      if (response.status === 201) {
        notification.success({ message: t('btr.new.success') });
        props.setIsNewModalOpen(false);
        props.setReload(true);
      }
    } catch (error) {
      setLoading(false);
      props.setIsNewModalOpen(false);
      return error;
    }

    setLoading(false);
  };

  return (
    <Modal
      footer={false}
      open={props.isNewModalOpen}
      onCancel={() => {
        form.resetFields();
        props.setIsNewModalOpen(false);
      }}
    >
      <MainTitle text={t('btr.new.title')} />
      <Form form={form} layout='vertical' onFinish={handleCreateNewRequest}>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              label={t('btr.trip_start')}
              name='tripStart'
              rules={[
                { required: true, message: t('btr.new.required') },
                {
                  validator: (_, value) => {
                    const tripStart = value;
                    const tripEnd = form.getFieldValue('tripEnd');
                    if (tripStart && tripEnd && tripStart.isAfter(tripEnd)) {
                      return Promise.reject(
                        new Error(t('btr.new.start_after_end'))
                      );
                    }
                    return Promise.resolve();
                  },
                },
              ]}
            >
              <DatePicker
                className='w-100'
                format={'DD.MM.YYYY. HH:mm'}
                showTime
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label={t('btr.trip_end')}
              name='tripEnd'
              rules={[
                { required: true, message: t('btr.new.required') },
                {
                  validator: (_, value) => {
                    const tripStart = form.getFieldValue('tripStart');
                    const tripEnd = value;

                    if (tripEnd && tripStart && tripEnd.isBefore(tripStart)) {
                      return Promise.reject(
                        new Error(t('btr.new.start_after_end'))
                      );
                    }
                    return Promise.resolve();
                  },
                },
              ]}
            >
              <DatePicker
                className='w-100'
                format={'DD.MM.YYYY. HH:mm'}
                showTime
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              label={t('btr.destination')}
              name='destination'
              rules={[{ required: true, message: t('btr.new.required') }]}
            >
              <Input className='w-100' />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              label={t('btr.info.employee-position')}
              name='employeePosition'
              rules={[{ required: true, message: t('btr.new.required') }]}
            >
              <Input className='w-100' />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              label={t('btr.info.trip-reason')}
              name='tripReason'
              rules={[{ required: true, message: t('btr.new.required') }]}
            >
              <Input.TextArea className='w-100' maxLength={120} showCount />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label={t('btr.info.vehicle-type.title')}
              rules={[{ required: true, message: t('btr.new.required') }]}
              name='vehicleType'
            >
              <Select
                className='w-100'
                options={Object.values(VehicleType).map((val) => ({
                  value: val,
                  label: t(`btr.info.vehicle-type.${val}`),
                }))}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label={t('btr.info.vehicle-brand')} name='vehicleBrand'>
              <Input className='w-100' />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item label={t('btr.info.licence-plate')} name='licencePlate'>
              <Input className='w-100' />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label={t('btr.info.advance')} name='advance'>
              <InputNumber
                className='w-100'
                addonAfter={'€'}
                decimalSeparator=','
                min={0}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label={t('btr.info.payment-nr')} name='paymentNr'>
              <Input className='w-100' />
            </Form.Item>
          </Col>
        </Row>
        <Flex justify='end'>
          <Form.Item>
            <Button htmlType='submit' loading={loading} className='primary'>
              {t('btr.confirm')}
            </Button>
          </Form.Item>
        </Flex>
      </Form>
    </Modal>
  );
};

export default NewBusinessTripRequest;
