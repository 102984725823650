import { useState } from 'react';
import { Outlet } from 'react-router-dom';

import SecurityPinForm from '../../../../common/forms/security-pin-form.component';

const Worksheets = () => {
  const [authorized, setAuthorized] = useState<boolean>(false);

  return !authorized ? (
    <SecurityPinForm setAuthorized={setAuthorized} />
  ) : (
    <Outlet />
  );
};

export default Worksheets;
