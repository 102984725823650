import { Header } from 'antd/es/layout/layout';

const HeaderLogo = () => {
  return (
    <Header className='header'>
      <div className='text-center'>
        <img
          src={'/assets/aspira-uciliste-w.png'}
          alt='Logo'
          className='img-fluid mw-100'
        />
      </div>
    </Header>
  );
};

export default HeaderLogo;
