import axios from 'axios';

import { API_URL } from '../../constants/constants';
import { ProgramDto } from '../../types/dto/event.dto';

export class Programs {
  async getPrograms(): Promise<ProgramDto[]> {
    const response = await axios({
      method: 'GET',
      url: `${API_URL}/api/programs`,
      responseType: 'json',
    });

    return response.data;
  }
}
