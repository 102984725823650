import { useState } from 'react';

import MonthlyWorkLogsList from './monthly-work-logs-list/monthly-work-logs-list.component';
import ViewMonthlyWorkLog from './view-monthly-work-log/view-monthly-work-log.component';

const WorkLogs = () => {
  const [monthlyWorkLogId, setMonthlyWorkLogId] = useState<string>('');

  return monthlyWorkLogId ? (
    <ViewMonthlyWorkLog
      monthlyWorkLogId={monthlyWorkLogId}
      setMonthlyWorkLogId={setMonthlyWorkLogId}
    />
  ) : (
    <MonthlyWorkLogsList setMonthlyWorkLogId={setMonthlyWorkLogId} />
  );
};

export default WorkLogs;
