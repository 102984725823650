import { useState } from 'react';
import { Outlet } from 'react-router';

import SecurityPinForm from '../../../../common/forms/security-pin-form.component';

const Partner = () => {
  const [authorized, setAuthorized] = useState<boolean>(false);

  return !authorized ? (
    <SecurityPinForm setAuthorized={setAuthorized} />
  ) : (
    <Outlet />
  );
};

export default Partner;
