import { Descriptions, Modal } from 'antd';
import { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';

import { BusinessTripRequestDto } from '../../../../../../../types/dto/work-logs.dto';

interface Props {
  detailsRequest: BusinessTripRequestDto;
  setDetailsRequest: Dispatch<
    SetStateAction<BusinessTripRequestDto | undefined>
  >;
  isDetailsModalOpen: boolean;
  setIsDetailsModalOpen: Dispatch<SetStateAction<boolean>>;
}

const BusinessTripRequestDetails = (props: Props) => {
  const { t } = useTranslation('work-logs');

  return (
    <Modal
      destroyOnClose
      footer={false}
      open={props.isDetailsModalOpen}
      onCancel={() => {
        props.setDetailsRequest(undefined);
        props.setIsDetailsModalOpen(false);
      }}
    >
      <Descriptions title={t('btr.options.info')} column={1} bordered>
        <Descriptions.Item label={t('btr.info.employee-position')}>
          {props.detailsRequest.employeePosition}
        </Descriptions.Item>
        <Descriptions.Item label={t('btr.info.trip-reason')}>
          {props.detailsRequest.tripReason}
        </Descriptions.Item>
        <Descriptions.Item label={t('btr.info.vehicle-type.title')}>
          {t(`btr.info.vehicle-type.${props.detailsRequest.vehicleType}`)}
        </Descriptions.Item>
        <Descriptions.Item label={t('btr.info.vehicle-brand')}>
          {props.detailsRequest.vehicleBrand}
        </Descriptions.Item>
        <Descriptions.Item label={t('btr.info.licence-plate')}>
          {props.detailsRequest.licencePlate}
        </Descriptions.Item>
        <Descriptions.Item label={t('btr.info.advance')}>
          {props.detailsRequest.advance} €
        </Descriptions.Item>
        <Descriptions.Item label={t('btr.info.payment-nr')}>
          {props.detailsRequest.paymentNr}
        </Descriptions.Item>
      </Descriptions>
    </Modal>
  );
};
export default BusinessTripRequestDetails;
