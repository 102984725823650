import {
  Button,
  Col,
  DatePicker,
  Flex,
  Form,
  Modal,
  notification,
  Row,
  Select,
} from 'antd';
import { useForm } from 'antd/es/form/Form';
import React, { Dispatch, SetStateAction, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Server } from '../../../../../../../api/server-index';
import { CreateAnnualLeaveRequestDto } from '../../../../../../../types/dto/work-logs.dto';
import { Location } from '../../../../../../../types/enum/location';
import MainTitle from '../../../../../../common/titles/main-title.component';

interface Props {
  isNewModalOpen: boolean;
  setIsNewModalOpen: Dispatch<SetStateAction<boolean>>;
  setReload: Dispatch<SetStateAction<boolean>>;
}

const NewAnnualLeaveRequest = (props: Props) => {
  const [form] = useForm<CreateAnnualLeaveRequestDto>();
  const { t } = useTranslation('work-logs');
  const [loading, setLoading] = useState<boolean>(false);

  const handleCreateNewRequest = async () => {
    const formValues = form.getFieldsValue();

    try {
      setLoading(true);
      const response =
        await Server.AnnualLeaveRequests.createAnnualLeaveRequest(formValues);
      if (response.status === 201) {
        notification.success({ message: t('alr.new.success') });
        props.setIsNewModalOpen(false);
        props.setReload(true);
      }
    } catch (error) {
      setLoading(false);
      props.setIsNewModalOpen(false);
      return error;
    }

    setLoading(false);
  };

  return (
    <Modal
      footer={false}
      open={props.isNewModalOpen}
      onCancel={() => props.setIsNewModalOpen(false)}
    >
      <MainTitle text={t('alr.new.title')} />
      <Form form={form} layout='vertical' onFinish={handleCreateNewRequest}>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              label={t('alr.start_date')}
              name='startDate'
              rules={[
                { required: true, message: t('btr.new.required') },
                {
                  validator: (_, value) => {
                    const startDate = value;
                    const endDate = form.getFieldValue('endDate');
                    if (startDate && endDate && startDate.isAfter(endDate)) {
                      return Promise.reject(
                        new Error(t('btr.new.start_after_end'))
                      );
                    }
                    return Promise.resolve();
                  },
                },
              ]}
            >
              <DatePicker className='w-100' format={'DD.MM.YYYY.'} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label={t('alr.end_date')}
              name='endDate'
              rules={[
                { required: true, message: t('btr.new.required') },
                {
                  validator: (_, value) => {
                    const startDate = form.getFieldValue('startDate');
                    const endDate = value;

                    if (endDate && startDate && endDate.isBefore(startDate)) {
                      return Promise.reject(
                        new Error(t('btr.new.start_after_end'))
                      );
                    }
                    return Promise.resolve();
                  },
                },
              ]}
            >
              <DatePicker className='w-100' format={'DD.MM.YYYY.'} />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              label={t('alr.location.title')}
              name='location'
              rules={[{ required: true, message: t('btr.new.required') }]}
            >
              <Select
                className='w-100'
                options={Object.values(Location).map((val) => ({
                  value: val,
                  label: t(`alr.location.${val}`),
                }))}
              />
            </Form.Item>
          </Col>
        </Row>
        <Flex justify='end'>
          <Form.Item>
            <Button htmlType='submit' loading={loading} className='primary'>
              {t('btr.confirm')}
            </Button>
          </Form.Item>
        </Flex>
      </Form>
    </Modal>
  );
};

export default NewAnnualLeaveRequest;
