import { LogoutOutlined } from '@ant-design/icons';
import { Button, Card, Descriptions, Flex, Spin } from 'antd';
import dayjs from 'dayjs';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import { Server } from '../../../../../api/server-index';
import { AuthContext } from '../../../../../context/auth-context';
import { EventDto } from '../../../../../types/dto/event.dto';
import LanguageSwitcher from '../../../sidebar/language-switcher.component';

const CheckIn = () => {
  const { logout } = useContext(AuthContext);
  const [params] = useSearchParams();
  const [loading, setLoading] = useState<boolean>(true);
  const [checkedIn, setCheckedIn] = useState<boolean>(false);
  const [event, setEvent] = useState<EventDto>();
  const eventId = params.get('eventId');
  const { t } = useTranslation('check-in');
  const [message, setMessage] = useState<string>('');

  const fetchEvent = useCallback(async () => {
    if (eventId) {
      setLoading(true);

      const response = await Server.EventCheckIn.checkIn(eventId);
      if (response.checkInStatus) {
        setCheckedIn(true);
      }
      setMessage(response.message);

      const eventRes = await Server.Events.getEventById(eventId);
      setEvent(eventRes);
      setLoading(false);
    }

    setLoading(false);
  }, [eventId]);

  useEffect(() => {
    fetchEvent();
  }, [fetchEvent]);

  const renderEventDetails = () => {
    return (
      <Descriptions bordered column={1} className='check-in-table mb-1'>
        <Descriptions.Item label={t('subjectName')}>
          {event?.subjectName}
        </Descriptions.Item>
        <Descriptions.Item label={t('date')}>
          <Flex gap={4}>
            <span>{`${dayjs(event?.dateStart).format('DD.MM.YYYY.')}`}</span>
            <span className='text-gray'>{`${dayjs(event?.dateStart).format(
              'HH:mm'
            )} - ${dayjs(event?.dateEnd).format('HH:mm')}`}</span>
          </Flex>
        </Descriptions.Item>
        {event?.classroom ? (
          <Descriptions.Item label={t('classroom')}>
            {event?.classroom}
          </Descriptions.Item>
        ) : (
          <></>
        )}
        {event?.notes ? (
          <Descriptions.Item label={t('notes')}>
            {event?.notes}
          </Descriptions.Item>
        ) : (
          <></>
        )}
      </Descriptions>
    );
  };

  return (
    <Spin spinning={loading}>
      <Flex justify='center' align='center' style={{ height: '60vh' }}>
        <Card className='check-in-card shadow-sm'>
          {eventId && checkedIn ? (
            <>
              <div className='text-center mb-2'>
                <img
                  src={'/assets/aspira-uciliste-w.png'}
                  alt='Logo'
                  className='img-fluid mw-100'
                />
              </div>
              <h2 className='text-center'>{t('success')}</h2>
              {renderEventDetails()}
              <Flex justify='space-between' gap={16}>
                <LanguageSwitcher mobile={false} />
                <Button
                  onClick={logout}
                  className='logout-button-sm'
                  icon={<LogoutOutlined />}
                >
                  {t('logout')}
                </Button>
              </Flex>
            </>
          ) : (
            <Flex vertical>
              <div className='text-center mb-2'>
                <img
                  src={'/assets/aspira-uciliste-w.png'}
                  alt='Logo'
                  className='img-fluid mw-100'
                />
              </div>
              <h2 className='text-center'>{t('error_title')}</h2>
              {eventId ? (
                <h3 className='mb-1 text-center'>{t(`message.${message}`)}</h3>
              ) : (
                <>
                  <h3 className='mb-1 text-center'>{t(`no_eventId`)}</h3>
                  <p className='m-0 text-center'>{t(`no_eventIdDesc`)}</p>
                </>
              )}
              {event?.id ? renderEventDetails() : <></>}
              <Flex justify='space-between' gap={16}>
                <LanguageSwitcher mobile={false} />
                <Button
                  onClick={logout}
                  className='logout-button-sm'
                  icon={<LogoutOutlined />}
                >
                  {t('logout')}
                </Button>
              </Flex>
            </Flex>
          )}
        </Card>
      </Flex>
    </Spin>
  );
};

export default CheckIn;
