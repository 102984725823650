import { Modal, DatePicker, notification, Row, Col } from 'antd';
import { RangePickerProps } from 'antd/es/date-picker';
import dayjs from 'dayjs';
import { Dispatch, SetStateAction, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Server } from '../../../../../../api/server-index';
import { CreateWorksheetDto } from '../../../../../../types/dto/worksheet.dto';
import MainTitle from '../../../../../common/titles/main-title.component';

interface NewWorksheetProps {
  isModalOpen: boolean;
  setIsModalOpen: Dispatch<SetStateAction<boolean>>;
  setReload: Dispatch<SetStateAction<boolean>>;
}

const NewWorksheet = (props: NewWorksheetProps) => {
  const { t } = useTranslation('worksheets');
  const [loading, setLoading] = useState<boolean>(false);
  const { isModalOpen, setIsModalOpen, setReload } = props;
  const [dateRange, setDateRange] = useState<CreateWorksheetDto>({
    dateStart: '',
    dateEnd: '',
  });

  const createWorksheet = async () => {
    setLoading(true);

    const response = await Server.Worksheets.createWorksheet(dateRange);

    if (response.status === 201) {
      notification.success({ message: t('new.success') });
      setIsModalOpen(false);
      resetDates();
      setReload(true);
    }

    setLoading(false);
  };

  const disabledDate: RangePickerProps['disabledDate'] = (current) => {
    return current && current > dayjs().startOf('day');
  };

  const resetDates = () => {
    setDateRange({ dateStart: '', dateEnd: '' });
  };

  return (
    <Modal
      open={isModalOpen}
      onCancel={() => {
        setIsModalOpen(false);
        resetDates();
      }}
      onOk={() => createWorksheet()}
      okText={t('new.confirm')}
      cancelText={t('new.cancel')}
      confirmLoading={loading}
      okButtonProps={{
        disabled: !dateRange.dateStart || !dateRange.dateEnd,
        ghost: true,
      }}
      destroyOnClose
    >
      <MainTitle text={t('new.title')} />
      <Row gutter={[16, 16]}>
        <Col xs={24} sm={12} md={12} xl={12}>
          <DatePicker
            format={'DD.MM.YYYY.'}
            className='w-100'
            disabledDate={disabledDate}
            placeholder={t('list.from')}
            onChange={(date) => {
              if (date) {
                setDateRange((prevFilterData) => ({
                  ...prevFilterData,
                  dateStart: date.startOf('day').toISOString(),
                }));
              }
            }}
          />
        </Col>
        <Col xs={24} sm={12} md={12} xl={12}>
          {' '}
          <DatePicker
            format={'DD.MM.YYYY.'}
            className='w-100'
            disabledDate={disabledDate}
            placeholder={t('list.to')}
            onChange={(dateTo) => {
              if (dateTo) {
                setDateRange((prevFilterData) => ({
                  ...prevFilterData,
                  dateEnd: dateTo.endOf('day').toISOString(),
                }));
              }
            }}
          />
        </Col>
      </Row>
    </Modal>
  );
};

export default NewWorksheet;
