import {
  Button,
  Card,
  DatePicker,
  Divider,
  Flex,
  Form,
  Input,
  notification,
} from 'antd';
import TextArea from 'antd/es/input/TextArea';
import dayjs from 'dayjs';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { Server } from '../../../../../../api/server-index';
import { EventDto } from '../../../../../../types/dto/event.dto';
import BackButton from '../../../../../common/buttons/back-button.component';
import Subtitle from '../../../../../common/titles/subtitle.component';
import { formItemLayout } from '../../../../form/form-item.layout';
import EventDetails from '../event-details/event-details.component';

const EditEvent = () => {
  const { t } = useTranslation(['events', 'common']);
  const location = useLocation();
  const navigate = useNavigate();
  const params = useParams();
  const [loading, setLoading] = useState<boolean>(false);
  const [event, setEvent] = useState<EventDto>(location.state);

  const fetchEvent = useCallback(async () => {
    const eventId = params.eventId;
    if (eventId) {
      const eventResponse = await Server.Events.getEventById(eventId);
      setEvent(eventResponse);
    }
  }, [params.eventId]);

  useEffect(() => {
    if (!event) {
      fetchEvent();
    }

    setLoading(false);
  }, [event, fetchEvent]);

  const initialValues = {
    subjectName: event?.subjectName,
    dateStart: dayjs(event?.dateStart),
    dateEnd: dayjs(event?.dateEnd),
    classroom: event?.classroom,
    notes: event?.notes,
  };

  const handleSubmit = async (values: any) => {
    setLoading(true);
    if (event) {
      const response = await Server.Events.updateEvent(event.id, {
        subjectName: values.subjectName,
        dateStart: values.dateStart.toISOString(),
        dateEnd: values.dateEnd.toISOString(),
        notes: values.notes,
        classroom: values.classroom,
      });

      if (response.status === 200) {
        notification.success({
          message: t('create_update_event.update_success'),
        });
        navigate('/events');
      }
    }

    setLoading(false);
  };

  return (
    <Card className='mb-2 shadow-sm'>
      <BackButton href={`/events${location?.search}`} />
      <EventDetails event={event} additionalInfo={false} />
      <Divider />
      <Subtitle text={t('create_update_event.update_title')} />
      <Form
        name='edit-event'
        className='mt-1'
        onFinish={handleSubmit}
        initialValues={initialValues}
        {...formItemLayout}
      >
        <Form.Item
          label={t('create_update_event.form.subject_name')}
          name='subjectName'
          rules={[
            { required: true, message: t('create_update_event.form.required') },
          ]}
          validateTrigger='onBlur'
        >
          <Input />
        </Form.Item>
        <Form.Item
          label={t('create_update_event.form.start')}
          name='dateStart'
          rules={[
            {
              required: true,
              message: t('create_update_event.form.required'),
            },
          ]}
          validateTrigger='onBlur'
        >
          <DatePicker showTime format={'DD.MM.YYYY. HH:mm'} className='w-100' />
        </Form.Item>
        <Form.Item
          label={t('create_update_event.form.end')}
          name='dateEnd'
          rules={[
            {
              required: true,
              message: t('create_update_event.form.required'),
            },
          ]}
          validateTrigger='onBlur'
        >
          <DatePicker showTime format={'DD.MM.YYYY. HH:mm'} className='w-100' />
        </Form.Item>
        <Form.Item
          label={t('create_update_event.form.classroom')}
          name='classroom'
        >
          <Input />
        </Form.Item>
        <Form.Item label={t('create_update_event.form.notes')} name='notes'>
          <TextArea maxLength={240} showCount />
        </Form.Item>
        <Flex justify='flex-end' className='mt-2'>
          <Form.Item>
            <Button htmlType='submit' loading={loading} type='primary' ghost>
              {t('create_update_event.form.submit')}
            </Button>
          </Form.Item>
        </Flex>
      </Form>
    </Card>
  );
};

export default EditEvent;
