import axios from 'axios';

import { API_URL } from '../../constants/constants';
import { GetStudentsInSubjectDto, UserDto } from '../../types/dto/user.dto';

export class User {
  async fetchUser(): Promise<UserDto> {
    const response = await axios({
      method: 'GET',
      url: `${API_URL}/api/users`,
      responseType: 'json',
      withCredentials: true,
    });
    return response.data as UserDto;
  }

  async getSecurityPin(): Promise<string> {
    const response = await axios({
      method: 'GET',
      url: `${API_URL}/api/users/securityPin`,
      responseType: 'json',
    });

    return response.data as string;
  }

  async updateSecurityPin(newSecurityPin: string) {
    return await axios({
      method: 'PATCH',
      url: `${API_URL}/api/users/securityPin`,
      responseType: 'text',
      data: { newSecurityPin },
    });
  }

  async sendResetPasswordLink(email: string) {
    const params = new URLSearchParams();
    params.append('email', email);

    return await axios({
      method: 'PATCH',
      url: `${API_URL}/api/users/send-reset-password-link`,
      params,
    });
  }

  async setPassword(setPasswordDto: {
    password: string;
    activationToken: string;
  }) {
    return await axios({
      method: 'PATCH',
      url: `${API_URL}/api/users/set-password`,
      data: setPasswordDto,
    });
  }

  async changePassword() {
    return await axios({
      method: 'PATCH',
      url: `${API_URL}/api/users/change-password`,
    });
  }

  async sendResetSecurityPinEmail() {
    return await axios({
      method: 'PATCH',
      url: `${API_URL}/api/users/send-reset-security-pin-email`,
    });
  }

  async changeSubscriptionStatus(status: boolean) {
    const params = new URLSearchParams();
    params.append('status', status.toString());

    return await axios({
      method: 'PATCH',
      url: `${API_URL}/api/users/email-subscription`,
      params,
    });
  }

  async getStudentsInSubject(getStudentsInSubjectDto: GetStudentsInSubjectDto) {
    const { page, pageSize, subjectId, academicYear, search } =
      getStudentsInSubjectDto;
    const params = new URLSearchParams();
    params.append('page', page.toString());
    params.append('perPage', pageSize.toString());
    params.append('academicYear', academicYear.toString());
    params.append('subjectId', subjectId);

    if (search) {
      params.append('search', search);
    }

    const response = await axios({
      method: 'GET',
      url: `${API_URL}/api/users/subject-students`,
      responseType: 'json',
      params,
    });

    return response.data;
  }
}
