import axios from 'axios';

import { API_URL } from '../../constants/constants';
import { CompensationDto } from '../../types/dto/compensation.dto';

export class Compensation {
  async getCompensation(): Promise<CompensationDto> {
    const response = await axios({
      method: 'GET',
      url: `${API_URL}/api/compensation`,
      responseType: 'json',
    });

    return response.data as CompensationDto;
  }
}
