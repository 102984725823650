import { Descriptions, Tag } from 'antd';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

import { WorksheetDto } from '../../../../../../types/dto/worksheet.dto';
import { WorkSheetStatus } from '../../../../../../types/enum/worksheet-status';
import { getTagClassName } from '../../../../../../utils/get-tag-class-name';

interface WorksheetDetailsProps {
  worksheet?: WorksheetDto;
}

const WorksheetDetails = (props: WorksheetDetailsProps) => {
  const { t } = useTranslation('worksheets');

  return (
    <Descriptions
      column={1}
      bordered
      size='small'
      title={`${t('edit.title')} #${props.worksheet?.worksheetNr}`}
      className='mt-1'
      labelStyle={{ width: '50%' }}
    >
      <Descriptions.Item label={t('list.period')}>{`${dayjs(
        props.worksheet?.startDate
      ).format('DD.MM.YYYY.')} - ${dayjs(props.worksheet?.endDate).format(
        'DD.MM.YYYY.'
      )}`}</Descriptions.Item>
      <Descriptions.Item label={t('list.created_at')}>{`${dayjs(
        props.worksheet?.createdDate
      ).format('DD.MM.YYYY. HH:mm')}`}</Descriptions.Item>
      <Descriptions.Item label={t('list.status')}>
        <Tag
          className={getTagClassName(
            props.worksheet?.status as WorkSheetStatus
          )}
        >
          {t(`status.${props.worksheet?.status}`)}
        </Tag>
      </Descriptions.Item>
    </Descriptions>
  );
};

export default WorksheetDetails;
