import { EditOutlined } from '@ant-design/icons';
import { Button, Descriptions, Flex, Spin, Tooltip } from 'antd';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

import { Server } from '../../../../../../api/server-index';
import { PartnerDto } from '../../../../../../types/dto/partner.dto';
import Subtitle from '../../../../../common/titles/subtitle.component';

const PartnerInfo = () => {
  const navigate = useNavigate();
  const { t } = useTranslation('partner');
  const [loading, setLoading] = useState<boolean>(true);
  const [partner, setPartner] = useState<PartnerDto>();

  const fetchPartner = useCallback(async () => {
    setLoading(true);
    const response = await Server.Partner.getPartner();
    if (!response) {
      navigate('new');
    } else {
      setPartner(response);
    }
  }, [navigate]);

  useEffect(() => {
    fetchPartner();
    setLoading(false);
  }, [fetchPartner]);

  return (
    <Spin spinning={loading}>
      {partner ? (
        <>
          <Flex gap={12} align='center'>
            <Subtitle text={t('info.title')} />

            {partner.editable ? (
              <Tooltip title={t('edit.title')}>
                <Button
                  onClick={() =>
                    navigate(`edit/${partner.pin}`, { state: partner })
                  }
                >
                  <EditOutlined />
                </Button>
              </Tooltip>
            ) : (
              <></>
            )}
          </Flex>
          <Descriptions bordered column={1} className='mb-2'>
            <Descriptions.Item label={t('form.pin')}>
              {partner.pin}
            </Descriptions.Item>

            <Descriptions.Item label={t('form.name')}>
              {partner.name}
            </Descriptions.Item>
            <Descriptions.Item label={t('form.type')}>
              {t(`types.${partner.type}`)}
            </Descriptions.Item>
            <Descriptions.Item label={t('form.iban')}>
              {partner.iban}
            </Descriptions.Item>
            <Descriptions.Item label={t('form.bankName')}>
              {partner.bankName}
            </Descriptions.Item>
            <Descriptions.Item label={t('form.address')}>
              {partner.address}
            </Descriptions.Item>
            <Descriptions.Item label={t('form.town')}>
              {partner.town}
            </Descriptions.Item>
            <Descriptions.Item label={t('form.postCode')}>
              {partner.postCode}
            </Descriptions.Item>
            <Descriptions.Item label={t('form.country')}>
              {partner.country}
            </Descriptions.Item>
            <Descriptions.Item label={t('form.phone')}>
              {partner.phone}
            </Descriptions.Item>
            <Descriptions.Item label={t('form.email')}>
              {partner.email}
            </Descriptions.Item>
            <Descriptions.Item label={t('form.website')}>
              {partner.website}
            </Descriptions.Item>
            {partner.intlBankAccountNr ? (
              <>
                <Descriptions.Item label={t('form.intlBankAccountNr')}>
                  {partner.intlBankAccountNr}
                </Descriptions.Item>
                <Descriptions.Item label={t('form.intlBankSwift')}>
                  {partner.intlBankSwift}
                </Descriptions.Item>
                <Descriptions.Item label={t('form.intlBankAdress')}>
                  {partner.intlBankAdress}
                </Descriptions.Item>
                <Descriptions.Item label={t('form.intlBankTown')}>
                  {partner.intlBankTown}
                </Descriptions.Item>
                <Descriptions.Item label={t('form.intlBankZip')}>
                  {partner.intlBankZip}
                </Descriptions.Item>
                <Descriptions.Item label={t('form.intlBankCountry')}>
                  {partner.intlBankCountry}
                </Descriptions.Item>
              </>
            ) : (
              <></>
            )}
          </Descriptions>
        </>
      ) : (
        <></>
      )}
    </Spin>
  );
};

export default PartnerInfo;
