import { encode } from 'base-64';
import React from 'react';

import { Spinner } from '../components/common/spinner';
import { AppLayout } from '../components/layout';
import { AuthContext } from '../context/auth-context';
import { AuthRouter } from './auth';

export function AppRouter() {
  const { checkAuth, isAuthenticated } = React.useContext(AuthContext);
  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    checkAuth().finally(() => {
      setLoading(false);
    });
  }, [checkAuth]);

  if (loading) return <Spinner />;

  function getAppRouter() {
    if (!isAuthenticated) {
      let intendedDest: string | undefined = window.location.href
        .replace(window.location.protocol + '//', '')
        .replace(window.location.hostname, '')
        .replace(':' + window.location.port + '/', '');

      if (intendedDest.length > 1) {
        //Remove all '=' as external services strip them.
        //We will return them later in auth context
        intendedDest = encode(intendedDest).replaceAll('=', '');
      } else {
        intendedDest = undefined;
      }

      return <AuthRouter intendedDestination={intendedDest} />;
    }

    return <AppLayout />;
  }

  return getAppRouter();
}
