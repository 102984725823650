import {
  Button,
  Card,
  Flex,
  Form,
  Input,
  Select,
  Switch,
  notification,
} from 'antd';
import { useForm } from 'antd/es/form/Form';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router';

import { Server } from '../../../../../../api/server-index';
import { PartnerDto } from '../../../../../../types/dto/partner.dto';
import { PartnerType } from '../../../../../../types/enum/partner-type';
import BackButton from '../../../../../common/buttons/back-button.component';
import Subtitle from '../../../../../common/titles/subtitle.component';
import { formItemLayoutLG } from '../../../../form/form-item.layout';

const EditPartner = () => {
  const { t } = useTranslation(['partner', 'common']);
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();
  const [form] = useForm<PartnerDto>();
  const [partner, setPartner] = useState<PartnerDto>(location.state);
  const [loading, setLoading] = useState<boolean>(true);
  const [showIntl, setShowIntl] = useState<boolean>(false);
  const [partnerToAssign, setPartnerToAssign] = useState<PartnerDto>();

  const fetchPartner = useCallback(async () => {
    setLoading(true);
    const partnerPin = params.partnerPin;

    if (partnerPin) {
      const response = await Server.Partner.getPartnerByPin(partnerPin);
      setPartner(response);
      if (partner.intlBankAccountNr) {
        setShowIntl(true);
      }
    } else {
      notification.error({ message: t('edit.not_available') });
      navigate('/partner');
    }
  }, [navigate, params.partnerPin, partner.intlBankAccountNr, t]);

  useEffect(() => {
    if (!partner) {
      fetchPartner();
    }

    setLoading(false);
  }, [fetchPartner, partner]);

  const updatePartner = async (values: any) => {
    setLoading(true);
    if (partner?.pin !== values.pin) {
      const response = await Server.Partner.assignPartner(values);
      if (response.status === 200) {
        notification.success({ message: t('new.success') });
        navigate(-1);
      }
    } else {
      const response = await Server.Partner.updatePartner(values);
      if (response.status === 200) {
        notification.success({ message: t('edit.success') });
        navigate(-1);
      }
    }

    setLoading(false);
  };

  const getPartnerByPin = async (pin: string) => {
    const response = await Server.Partner.getPartnerByPin(pin);
    if (response) {
      setPartnerToAssign(response);
      if (response.intlBankAccountNr) {
        setShowIntl(true);
      }
      form.setFieldsValue(response);
    }
  };

  return (
    <Card className='mb-2 shadow-sm'>
      <BackButton href={'/partner'} />
      <Subtitle text={t('edit.title')} />
      <Form
        form={form}
        name='edit-partner'
        className='mt-1'
        initialValues={partner}
        onFinish={updatePartner}
        {...formItemLayoutLG}
      >
        <Form.Item
          label={t('form.pin')}
          name='pin'
          rules={[
            { required: true, message: t('form.required') },
            {
              pattern: /^\d{11}$/,
              message: t('form.validate.pin'),
            },
          ]}
          validateTrigger='onBlur'
        >
          <Input
            onChange={(e) => {
              if (e.target.value.length < 11 && partnerToAssign) {
                setPartnerToAssign(undefined);
                form.resetFields();
              }
              if (e.target.value.length === 11) {
                getPartnerByPin(e.target.value);
              }
            }}
          />
        </Form.Item>
        <Form.Item
          label={t('form.name')}
          name='name'
          rules={[{ required: true, message: t('form.required') }]}
          validateTrigger='onBlur'
        >
          <Input />
        </Form.Item>
        <Form.Item
          label={t('form.type')}
          name='type'
          rules={[{ required: true, message: t('form.required') }]}
          validateTrigger='onBlur'
        >
          <Select
            options={Object.values(PartnerType).map((type) => {
              return { key: type, value: type, label: t(`types.${type}`) };
            })}
          />
        </Form.Item>
        <Form.Item label={t('form.iban')} name='iban'>
          <Input />
        </Form.Item>
        <Form.Item label={t('form.bankName')} name='bankName'>
          <Input />
        </Form.Item>
        <Form.Item label={t('form.address')} name='address'>
          <Input />
        </Form.Item>
        <Form.Item
          label={t('form.postCode')}
          name='postCode'
          rules={[
            {
              pattern: /^\d+$/,
              message: t('form.validate.post_code'),
            },
          ]}
          validateTrigger='onBlur'
        >
          <Input />
        </Form.Item>
        <Form.Item label={t('form.town')} name='town'>
          <Input />
        </Form.Item>
        <Form.Item label={t('form.country')} name='country'>
          <Input />
        </Form.Item>
        <Form.Item label={t('form.phone')} name='phone'>
          <Input />
        </Form.Item>
        <Form.Item
          label={t('form.email')}
          name='email'
          rules={[{ type: 'email', message: t('form.validate.email') }]}
          validateTrigger='onBlur'
        >
          <Input />
        </Form.Item>
        <Form.Item label={t('form.website')} name='website'>
          <Input />
        </Form.Item>
        <Flex align='center' gap={8}>
          <h4>{t('new.title_intl')}</h4>
          <Switch checked={showIntl} onChange={() => setShowIntl(!showIntl)} />
        </Flex>
        {showIntl ? (
          <>
            <Form.Item
              label={t('form.intlBankAccountNr')}
              name='intlBankAccountNr'
            >
              <Input />
            </Form.Item>
            <Form.Item label={t('form.intlBankSwift')} name='intlBankSwift'>
              <Input />
            </Form.Item>
            <Form.Item label={t('form.intlBankAdress')} name='intlBankAdress'>
              <Input />
            </Form.Item>
            <Form.Item label={t('form.intlBankTown')} name='intlBankTown'>
              <Input />
            </Form.Item>
            <Form.Item label={t('form.intlBankZip')} name='intlBankZip'>
              <Input />
            </Form.Item>
            <Form.Item label={t('form.intlBankCountry')} name='intlBankCountry'>
              <Input />
            </Form.Item>
          </>
        ) : (
          <></>
        )}
        <Flex justify='flex-end'>
          <Button loading={loading} htmlType='submit' type='primary' ghost>
            {t('edit.submit')}
          </Button>
        </Flex>
      </Form>
    </Card>
  );
};

export default EditPartner;
