import { FilterOutlined } from '@ant-design/icons';
import { Badge } from 'antd';

interface Props {
  count: number;
}

const FilterIcon = ({ count }: Props) => {
  return (
    <Badge count={count} size='small'>
      <FilterOutlined className='filter-icon' />
    </Badge>
  );
};

export default FilterIcon;
