import {
  DeleteOutlined,
  EditOutlined,
  QrcodeOutlined,
  TeamOutlined,
} from '@ant-design/icons';
import {
  Button,
  DatePicker,
  Empty,
  Flex,
  Modal,
  Popconfirm,
  Spin,
  Table,
  TableProps,
  Tooltip,
  notification,
} from 'antd';
import dayjs from 'dayjs';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';

import { Server } from '../../../../../../api/server-index';
import { SearchDataSelection } from '../../../../../../types/common/search-data-selection.component';
import { PaginatedData } from '../../../../../../types/dto/common.dto';
import { EventCheckInCodeDto } from '../../../../../../types/dto/event-check-in.dto';
import { EventDto } from '../../../../../../types/dto/event.dto';
import { setNavigationPath } from '../../../../../../utils/navigation-params';
import NewButton from '../../../../../common/buttons/new-button.component';
import FilterIcon from '../../../../../common/icons/filter-icon.component';
import MainTitle from '../../../../../common/titles/main-title.component';
import EventQRCode from './qr-code/event-qr-code.component';

const EventsList = () => {
  const { t } = useTranslation('events');
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [events, setEvents] = useState<PaginatedData<EventDto[]>>();
  const [loading, setLoading] = useState<boolean>(true);
  const [reload, setReload] = useState<boolean>(false);
  const [checkInData, setCheckInData] = useState<{
    event: EventDto;
    qrData: EventCheckInCodeDto;
  }>();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [dataSelection, setDataSelection] = useState<SearchDataSelection>({
    page: searchParams.get('page') ? Number(searchParams.get('page')) : 1,
    pageSize: searchParams.get('pageSize')
      ? Number(searchParams.get('pageSize'))
      : 10,
  });
  const [filter, setFilter] = useState<{ date: string; dateTo: string }>({
    date:
      searchParams.get('date') ?? dayjs().startOf('day').format('DD-MM-YYYY'),
    dateTo:
      searchParams.get('dateTo') ??
      dayjs().add(1, 'month').format('DD-MM-YYYY'),
  });

  const setSearchURL = useCallback(() => {
    setSearchParams(setNavigationPath(filter, dataSelection));
  }, [dataSelection, filter, setSearchParams]);

  const fetchEvents = useCallback(async () => {
    setLoading(true);
    const response = await Server.Events.getEvents(
      dayjs(filter.date, 'DD-MM-YYYY').toISOString(),
      dayjs(filter.dateTo, 'DD-MM-YYYY').toISOString(),
      dataSelection.page,
      dataSelection.pageSize
    );

    setEvents(response);
    setLoading(false);
  }, [dataSelection, filter]);

  useEffect(() => {
    fetchEvents();
    setSearchURL();
    setReload(false);
  }, [fetchEvents, reload, setSearchURL]);

  const getQRcode = async (record: EventDto) => {
    try {
      const response = await Server.EventCheckIn.getCheckInCode(record.id);
      setCheckInData({ event: record, qrData: response });
      setIsModalOpen(true);
    } catch (error) {}
  };

  const handleDeleteEvent = async (id: string) => {
    const response = await Server.Events.deleteEvent(id);

    if (response.status === 200) {
      notification.success({ message: t('event_list.delete.success') });
      setReload(true);
    }
  };

  const isOutsideCheckInTimeframe = (dateStart: string, dateEnd: string) => {
    const now = dayjs();
    if (
      dayjs(dateStart).subtract(10, 'minute').isBefore(now) &&
      dayjs(dateEnd).isAfter(now)
    ) {
      return false;
    } else return true;
  };

  const columns: TableProps<EventDto>['columns'] = [
    {
      key: 'date',
      title: t('event_list.date_and_time'),
      dataIndex: 'id',
      width: '15%',
      filterDropdown: (
        <Flex gap={8} className='p-1'>
          <Flex align='center' gap={6}>
            {t('event_list.filter.from')}:
            <DatePicker
              format={'DD.MM.YYYY.'}
              className='w-100'
              value={dayjs(filter.date, 'DD-MM-YYYY')}
              onChange={(date) => {
                if (date) {
                  setFilter((prevFilterData) => ({
                    ...prevFilterData,
                    date: date.format('DD-MM-YYYY'),
                  }));
                  setDataSelection((prevVal) => ({
                    ...prevVal,
                    page: 1,
                  }));
                }
              }}
            />
          </Flex>
          <Flex align='center' gap={6}>
            {t('event_list.filter.to')}:
            <DatePicker
              format={'DD.MM.YYYY.'}
              className='w-100'
              value={dayjs(filter.dateTo, 'DD-MM-YYYY')}
              onChange={(dateTo) => {
                if (dateTo) {
                  setFilter((prevFilterData) => ({
                    ...prevFilterData,
                    dateTo: dateTo.format('DD-MM-YYYY'),
                  }));
                }
              }}
            />
          </Flex>
        </Flex>
      ),
      filterIcon: <FilterIcon count={2} />,
      render: (id, record) => (
        <>
          <p className='m-0'>{`${dayjs(record.dateStart).format(
            'DD.MM.YYYY.'
          )}`}</p>
          <p className='m-0'>{`${dayjs(record.dateStart).format(
            'HH:mm'
          )} - ${dayjs(record.dateEnd).format('HH:mm')}`}</p>
        </>
      ),
    },
    {
      key: 'subjectName',
      title: t('event_list.subject_name'),
      dataIndex: 'subjectName',
      width: '35%',
      render: (text, record) => (
        <Flex vertical>
          <h4 className='m-0'>{text}</h4>
          {record.notes ? (
            <small className='text-gray'>{record.notes}</small>
          ) : (
            <></>
          )}
        </Flex>
      ),
    },
    {
      key: 'classroom',
      title: t('event_list.classroom'),
      dataIndex: 'classroom',
      width: '15%',
      render: (text) => <span>{text}</span>,
    },
    {
      key: 'actions',
      title: t('event_list.options'),
      dataIndex: 'id',
      width: '20%',
      render: (id, record) => (
        <Flex gap={8}>
          <Tooltip
            title={
              isOutsideCheckInTimeframe(record.dateStart, record.dateEnd)
                ? t('event_list.QR.warning')
                : t('event_list.QR.title')
            }
          >
            <Button
              onClick={() => getQRcode(record)}
              disabled={isOutsideCheckInTimeframe(
                record.dateStart,
                record.dateEnd
              )}
            >
              <QrcodeOutlined />
            </Button>
          </Tooltip>

          <Tooltip title={t('check_ins.title')}>
            <Link
              to={`checked-in/${id}?${searchParams.toString()}`}
              state={record}
            >
              <Button>
                <TeamOutlined />
              </Button>
            </Link>
          </Tooltip>
          <Tooltip title={t('create_update_event.update_title')}>
            <Link to={`edit/${id}?${searchParams.toString()}`} state={record}>
              <Button>
                <EditOutlined />
              </Button>
            </Link>
          </Tooltip>

          <Popconfirm
            title={t('event_list.delete.title')}
            onConfirm={() => handleDeleteEvent(id)}
            okText={t('event_list.delete.yes')}
            cancelText={t('event_list.delete.no')}
          >
            <Button danger>
              <DeleteOutlined />
            </Button>
          </Popconfirm>
        </Flex>
      ),
    },
  ];

  return (
    <Spin spinning={loading}>
      <MainTitle text={t('event_list.title')} />
      <NewButton onClick={() => navigate(`new?${searchParams.toString()}`)} />
      <Table
        className='mt-1 mb-2'
        dataSource={events?.records}
        columns={columns}
        scroll={{
          x: 800,
        }}
        rowKey={(val) => val.id}
        pagination={{
          hideOnSinglePage: true,
          total: events?.totalCount,
          pageSize: dataSelection.pageSize,
          current: dataSelection.page,
          onChange: (page: number, pageSize?: number) => {
            const newPageSize = pageSize || dataSelection.pageSize;
            // Lets get back to first page on size change
            if (newPageSize !== dataSelection.pageSize) page = 1;

            setDataSelection({
              ...dataSelection,
              page,
              pageSize: newPageSize,
            });
            const node = document.querySelector('.ant-card-body');
            node?.scrollIntoView();
          },
        }}
        locale={{
          emptyText: (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description={t('common:no_data')}
            />
          ),
        }}
      />
      <Modal
        open={isModalOpen}
        onCancel={() => setIsModalOpen(false)}
        destroyOnClose
        footer={null}
        width={720}
      >
        {checkInData ? <EventQRCode checkInData={checkInData} /> : <></>}
      </Modal>
    </Spin>
  );
};

export default EventsList;
