import { Button, Flex, Form, Modal, notification, Tabs, TabsProps } from 'antd';
import dayjs from 'dayjs';
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';

import { Server } from '../../../../../../../../api/server-index';
import MainTitle from '../../../../../../../common/titles/main-title.component';
import AbsenceForm from './absence/absence-form.component';
import PresenceForm from './presence/presence-form.component';

interface Props {
  isBatchModalOpen: boolean;
  setIsBatchModalOpen: Dispatch<SetStateAction<boolean>>;
  setReload: Dispatch<SetStateAction<boolean>>;
  selectedRowKeys: string[];
  setSelectedRowKeys: Dispatch<SetStateAction<string[]>>;
  monthlyWorkLogId?: string;
}

const BatchEditWorkLog = (props: Props) => {
  const { t } = useTranslation('work-logs');
  const {
    isBatchModalOpen,
    setIsBatchModalOpen,
    setReload,
    selectedRowKeys,
    monthlyWorkLogId,
    setSelectedRowKeys,
  } = props;
  const [loading, setLoading] = useState<boolean>(false);
  const [form] = Form.useForm();

  const fetchDailyWorkLog = useCallback(async () => {
    const dailyWorkLog = await Server.WorkLogs.getDailyWorkLogById(
      selectedRowKeys[0]
    );

    const transformedDailyWorkLog = {
      ...dailyWorkLog,
      startTime: dailyWorkLog.startTime
        ? dayjs(dailyWorkLog.startTime, 'HH:mm:ss')
        : null,
      endTime: dailyWorkLog.endTime
        ? dayjs(dailyWorkLog.endTime, 'HH:mm:ss')
        : null,
    };

    form.setFieldsValue(transformedDailyWorkLog);
  }, [form, selectedRowKeys]);

  useEffect(() => {
    if (selectedRowKeys.length === 1) {
      fetchDailyWorkLog();
    } else {
      form.resetFields();
    }
  }, [fetchDailyWorkLog, form, selectedRowKeys.length]);

  const handleBatchEdit = async (values: any) => {
    if (values.startTime) {
      values.startTime = form.getFieldValue('startTime')?.format('HH:mm');
    }
    if (values.endTime) {
      values.endTime = form.getFieldValue('endTime')?.format('HH:mm');
    }

    Object.keys(values).forEach((key) => {
      if (values[key] === undefined) {
        values[key] = null;
      }
    });

    if (monthlyWorkLogId) {
      const dailyWorkLogs = selectedRowKeys.map((key) => ({
        id: key,
        ...values,
      }));

      try {
        setLoading(true);
        await Server.WorkLogs.updateDailyWorkLogs({
          dailyWorkLogs,
          monthlyWorkLogId,
        });

        setIsBatchModalOpen(false);
        setSelectedRowKeys([]);
        form.resetFields();
        setReload(true);
      } catch (error) {
        setLoading(false);
        return error;
      } finally {
        notification.success({ message: t('edit.success') });
      }

      setLoading(false);
    }
  };

  const tabItems: TabsProps['items'] = [
    {
      key: '1',
      label: t('view.columns.presence'),
      children: <PresenceForm form={form} />,
    },
    {
      key: '2',
      label: t('view.columns.absence'),
      children: <AbsenceForm />,
    },
  ];

  return (
    <Modal
      className='batch-edit-modal'
      destroyOnClose={true}
      okText={t('new.confirm')}
      cancelText={t('new.cancel')}
      open={isBatchModalOpen}
      confirmLoading={loading}
      onCancel={() => {
        setIsBatchModalOpen(false);
        setSelectedRowKeys([]);
        form.resetFields();
      }}
      footer={false}
    >
      <MainTitle text={t('edit.title')} />
      <Form form={form} layout='vertical' onFinish={handleBatchEdit}>
        <Tabs defaultActiveKey='1' items={tabItems} destroyInactiveTabPane />
        <Flex justify='end'>
          <Form.Item>
            <Button htmlType='submit' loading={loading} className='primary'>
              {t('new.confirm')}
            </Button>
          </Form.Item>
        </Flex>
      </Form>
    </Modal>
  );
};

export default BatchEditWorkLog;
