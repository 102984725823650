import axios from 'axios';

import { API_URL } from '../../constants/constants';
import { PartnerDto } from '../../types/dto/partner.dto';

export class Partner {
  async getPartner(): Promise<PartnerDto> {
    const response = await axios({
      method: 'GET',
      url: `${API_URL}/api/partner`,
      responseType: 'json',
    });

    return response.data as PartnerDto;
  }

  async getPartnerByPin(partnerPin: string): Promise<PartnerDto> {
    const params = new URLSearchParams();
    params.append('partnerPin', partnerPin);

    const response = await axios({
      method: 'GET',
      url: `${API_URL}/api/partner/pin`,
      responseType: 'json',
      params,
    });

    return response.data as PartnerDto;
  }

  async createPartner(createPartnerDto: PartnerDto) {
    return await axios({
      method: 'POST',
      url: `${API_URL}/api/partner`,
      responseType: 'json',
      data: createPartnerDto,
    });
  }

  async assignPartner(createPartnerDto: PartnerDto) {
    return await axios({
      method: 'PATCH',
      url: `${API_URL}/api/partner/assign`,
      responseType: 'json',
      data: createPartnerDto,
    });
  }

  async updatePartner(updatePartnerDto: PartnerDto) {
    return await axios({
      method: 'PATCH',
      url: `${API_URL}/api/partner`,
      responseType: 'json',
      data: updatePartnerDto,
    });
  }
}
