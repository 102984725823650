const UserManual = () => {
  return (
    <div style={{ overflow: 'auto', height: '100vh' }}>
      <iframe
        src='assets/lecturer-user-manual.pdf'
        title='user-manual'
        width={'100%'}
        height={'100%'}
      />
    </div>
  );
};

export default UserManual;
